import React from 'react';
import { UserMenu, MenuItemLink, Logout } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import { useProfile } from '../pages/profiles';

const MyUserMenu = (props: any) => {
  const { profileVersion } = useProfile();
  const CustomLink = (props: any) => (
    <li>
      <MenuItem {...props} />
    </li>
  );

  return (
    <UserMenu key={profileVersion} {...props} logout={<Logout button />}>
      <MenuItemLink
        //component={CustomLink}
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<SettingsIcon />}
        sidebarIsOpen={false}
      />
    </UserMenu>
  );
};

export default MyUserMenu;
