import React, { MouseEventHandler, FC } from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  useTranslate,
  EditProps,
  ToolbarProps,
} from 'react-admin';
import Button from '@material-ui/core/Button';

// interface TagEditProps extends EditProps {
//   onCancel?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
// }

// const TagEditToolbar = (props: TagEditProps) => {
//   const { onCancel, ...rest } = props;
//   const toolbarProps: Partial<ToolbarProps> = rest;
//   const translate = useTranslate();
//   return (
//     <Toolbar {...(toolbarProps as ToolbarProps)}>
//       <SaveButton />
//       <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// };

// const TagEdit: FC<TagEditProps> = ({ onCancel, ...props }: TagEditProps) => (
//   <Edit title=" " {...props}>
//     <SimpleForm toolbar={<TagEditToolbar onCancel={onCancel} />}>
//       <TextInput source="name" validate={required()} />
//     </SimpleForm>
//   </Edit>
// );

type TagEditProps = {
  onCancel: () => void;
};

const TagEditToolbar = ({ onCancel, ...rest }: Partial<ToolbarProps> & TagEditProps) => {
  const translate = useTranslate();
  return (
    <Toolbar {...(rest as unknown)}>
      <SaveButton />
      <Button onClick={onCancel} variant="text">
        {translate('ra.action.cancel')}
      </Button>
    </Toolbar>
  );
};

const TagEdit: FC<EditProps & TagEditProps> = ({ onCancel, ...props }: EditProps & TagEditProps) => (
  <Edit title=" " {...props}>
    <SimpleForm toolbar={<TagEditToolbar onCancel={onCancel} />}>
      <TextInput source="title" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default TagEdit;
