import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { Sidebar } from 'react-admin';
import SwipeableDrawer, { SwipeableDrawerProps } from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import PageSimpleSidebarContent, { PageSidebarContentProps } from './PageSimpleSidebarContent';

export type PageSidebarVariantType = 'permanent' | 'persistent' | 'temporary';

export interface PageSidebarProps {
  classes: any;
  variant: PageSidebarVariantType;
  position: 'left' | 'right';
  header?: any;
  content: any;
  rootRef?: React.RefObject<HTMLDivElement>;
  innerScroll?: boolean;
  sidebarInner?: boolean;
}

export interface PageSidebarRefProps {
  toggleSidebar: () => void;
}

const PageSimpleSidebar: React.ForwardRefRenderFunction<PageSidebarRefProps, PageSidebarProps> = (
  props: PageSidebarProps,
  ref: React.ForwardedRef<PageSidebarRefProps>
) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = props;

  useImperativeHandle(ref, () => ({
    toggleSidebar: handleToggleDrawer,
  }));

  const handleToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Sidebar classes={{}}>
      <PageSimpleSidebarContent {...(props as PageSidebarContentProps)} />
    </Sidebar>
  );

  //   return (
  //     <>
  //       <Hidden lgUp={props.variant === 'permanent'}>
  //         <SwipeableDrawer
  //           variant="temporary"
  //           anchor={props.position}
  //           open={isOpen}
  //           onOpen={ev => {}}
  //           onClose={ev => handleToggleDrawer()}
  //           disableSwipeToOpen
  //           classes={{
  //             root: clsx(classes.sidebarWrapper, props.variant),
  //             paper: clsx(
  //               classes.sidebar,
  //               props.variant,
  //               props.position === 'left' ? classes.leftSidebar : classes.rightSidebar
  //             ),
  //           }}
  //           ModalProps={{
  //             keepMounted: true, // Better open performance on mobile.
  //           }}
  //           container={props.rootRef.current}
  //           BackdropProps={{
  //             classes: {
  //               root: classes.backdrop,
  //             },
  //           }}
  //           style={{ position: 'absolute' }}
  //         >
  //           <PageSimpleSidebarContent {...(props as PageSidebarContentProps)} />
  //         </SwipeableDrawer>
  //       </Hidden>
  //       {props.variant === 'permanent' && (
  //         <Hidden mdDown>
  //           <Drawer
  //             variant="permanent"
  //             className={clsx(classes.sidebarWrapper, props.variant)}
  //             open={isOpen}
  //             classes={{
  //               paper: clsx(
  //                 classes.sidebar,
  //                 props.variant,
  //                 props.position === 'left' ? classes.leftSidebar : classes.rightSidebar
  //               ),
  //             }}
  //           >
  //             <PageSimpleSidebarContent {...(props as PageSidebarContentProps)} />
  //           </Drawer>
  //         </Hidden>
  //       )}
  //     </>
  //   );
};

export default forwardRef(PageSimpleSidebar);
