import React, { useRef, FC } from 'react';
import HotelEditOutlined from '@material-ui/icons/HotelOutlined';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import LeftMenu from './LeftMenu';

interface Props {}

type HotelLayoutProps = Props;

const Layout: FC<HotelLayoutProps> = props => {
  return <LayoutWithSidebar leftMenu={<LeftMenu />}>{props.children}</LayoutWithSidebar>;
};

export default Layout;
