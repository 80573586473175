export enum Theme {
  Light = "Light",
  Dark = "Dark",
}

export type NotificationState = {
  readonly theme: Theme;
  readonly drawerToggled: boolean;
  readonly bangeValue: number;
};

export const CHANGE_THEME = "NOTIFICATION/CHANGE_THEME";
export const DRAWER_TOGGLED = "NOTIFICATION/DRAWER_TOGGLED";
export const CHANGE_BANGE_VALUE = "NOTIFICATION/CHANGE_BANGE_VALUE";

export type ChangeThemeAction = {
  readonly type: typeof CHANGE_THEME
  readonly payload: Theme
};

export type DrawerToggledAction = {
  readonly type: typeof DRAWER_TOGGLED
  readonly payload: boolean
};

export type ChangeBangeValueAction = {
  readonly type: typeof CHANGE_BANGE_VALUE
  readonly payload: number
};

export type NotificationAction
  = ChangeThemeAction | DrawerToggledAction | ChangeBangeValueAction;
