import React, {createContext, useContext} from 'react'
import {AppRoute, AppConfig} from './types'

export const AppContext = createContext<AppContextProps>({} as AppContextProps);

interface AppContextProps {
    routes:AppRoute[];
    appsConfigs:AppConfig[];
}

export const useAppContext = () => {
    return useContext(AppContext);
}
