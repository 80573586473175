import { CHANGE_THEME, ChangeThemeAction, Theme } from "./types";
import { DRAWER_TOGGLED, DrawerToggledAction } from "./types";
import { CHANGE_BANGE_VALUE, ChangeBangeValueAction } from "./types";

export function changeTheme(theme: Theme): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    payload: theme
  };
}

export function drawerToggle(open: boolean): DrawerToggledAction {
  return {
    type: DRAWER_TOGGLED,
    payload: open
  };
}


export function changeBangeValue(bangeValue: number): ChangeBangeValueAction {
  return {
    type: CHANGE_BANGE_VALUE,
    payload: bangeValue
  };
}
