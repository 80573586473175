import React, { forwardRef, cloneElement, useCallback, FC, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ReduxState, setSidebarVisibility } from 'ra-core';
import { MenuItemProps, TooltipProps, useMediaQuery, Theme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';

const NavLinkRef = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => <NavLink innerRef={ref} {...props} />);

const useStyles = makeStyles(
  theme => ({
    liRoot: {
      padding: '0',
      margin: '0',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
    },

    root: {
      display: 'block',
      width: '100%',
      fontSize: '0.7rem',
      position: 'relative',
      textAlign: 'center',
      textDecoration: 'none',
      transition: 'opacity .25s',
      opacity: '0.8',
      padding: '.6rem .5rem .4rem',
      color: theme.palette.sidebar.contrastText,
      '&:hover': {
        opacity: '1',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.sidebar.light,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      // '&.Mui-focusVisible,&.MuiListItem-root.Mui-focusVisible': {
      //   background:
      //     'rgb(255 255 255 / 0%) radial-gradient(circle, rgb(255 255 255 / 20%) 0%, rgb(71 74 120 / 35%) 68%)',
      // },

      // '&.Mui-selected,&.Mui-selected:hover': {
      //   background:
      //     'rgb(255 255 255 / 0%) radial-gradient(circle, rgb(255 255 255 / 20%) 0%, rgb(71 74 120 / 35%) 68%)',
      // },
    },
    focusVisible: {
      background: 'rgb(255 255 255 / 0%) radial-gradient(circle, rgb(255 255 255 / 20%) 0%, rgb(71 74 120 / 35%) 68%)',
    },
    active: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.sidebar.light,
      opacity: '1',
      '&  svg': {
        color: `${theme.palette.primary.main}!important`,
      },

      '&::before': {
        borderLeft: 'solid .2rem #6264a7',
        borderRadius: '.4rem',
        backgroundColor: theme.palette.primary.main,
        content: '""',
        height: '80%',
        left: '.2rem',
        position: 'absolute',
        top: '.4rem',
        bottom: '.4rem',
      },
    },

    // .MuiListItem-root.Mui-focusVisible {
    //   background-color: rgba(0, 0, 0, 0.08);
    // }
    // .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    //   background-color: rgba(0, 0, 0, 0.08);
    // }

    icon: {
      minWidth: 'auto',
      display: 'block',
      '& > svg': {
        fontSize: '1.8rem',
        color: theme.palette.sidebar.contrastText,
        opacity: '0.9',
      },
    },
    iconText: {
      padding: '0',
      margin: '0',
    },
  }),
  { name: 'HPAppMenuItemLink' }
);

const AppMenuItemLink: FC<AppMenuItemLinkProps> = forwardRef((props, ref) => {
  console.log(ref);
  const { classes: classesOverride, className, primaryText, leftIcon, onClick, tooltipProps, ...rest } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const handleMenuTap = useCallback(
    e => {
      // if (isSmall) {
      //   dispatch(setSidebarVisibility(false));
      // }
      onClick && onClick(e);
    },
    [dispatch, isSmall, onClick]
  );

  const renderMenuItem = () => {
    return (
      <ListItem
        classes={{
          root: classes.liRoot,
        }}
      >
        <MenuItem
          disableRipple={false}
          focusVisibleClassName={classes.focusVisible}
          className={classnames(classes.root, className)}
          activeClassName={classes.active}
          component={props.to ? NavLinkRef : null}
          ref={ref}
          tabIndex={0}
          {...rest}
          onClick={handleMenuTap}
        >
          {leftIcon && (
            <ListItemIcon className={classes.icon}>
              {cloneElement(leftIcon, {
                titleAccess: primaryText,
              })}
            </ListItemIcon>
          )}
          <ListItemText className={classes.iconText} primary={primaryText} disableTypography />
        </MenuItem>
      </ListItem>
    );
  };

  return open ? (
    renderMenuItem()
  ) : (
    <Tooltip title={primaryText ? primaryText : ''} placement="right" {...tooltipProps}>
      {renderMenuItem()}
    </Tooltip>
  );
});

interface Props {
  leftIcon?: ReactElement;
  primaryText?: ReactNode;
  staticContext?: StaticContext;
  tooltipProps?: TooltipProps;
  //isActive:boolean;
  type: 'button' | 'link';
}

export type AppMenuItemLinkProps = Props & NavLinkProps & MenuItemProps<'li', { button?: true }>; // HACK: https://github.com/mui-org/material-ui/issues/16245

AppMenuItemLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  staticContext: PropTypes.object,
  //isActive: PropTypes.bool,
  //type: PropTypes.oneOf(['button', 'link']).isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

AppMenuItemLink.defaultProps = {
  type: 'link',
  //isActive:false,
};

export default AppMenuItemLink;
