import { appReducer as currentAppReducer, initialAppState as currentInitialAppState } from "./apps/reducer";
import { configReducer, initialConfigState } from "../containers/config/store/reducer";
import { notificationReducer, initialNotificationState } from "../containers/notification/store/reducer";
import { InternalAppState } from "./types";

export const initialAppState = (): InternalAppState => ({
  config: initialConfigState(),
  notification: initialNotificationState(),
  apps:currentInitialAppState()
});

export const appReducer = {
  config: configReducer,
  notification:notificationReducer,
  apps:currentAppReducer
};
