import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import ContactsIcon from '@material-ui/icons/ContactsOutlined';

import { AppConfig, AppRoute } from '../../types';

const temp = () => <div />;

const customersConfig: AppConfig = {
  // invoices: {
  // 	layout: {
  // 		config: {}
  // 	} //lazy(() => import('./invoicesEdit')),
  // },
  key: 'CustomersApp',
  name: 'Klienti',
  icon: ContactsIcon,
  routes: [
    {
      group: 'home',
      key: 'customers/list',
      name: 'Klienti',
      path: '/customers',
      icon: ContactsIcon,
      customRouteProps: {
        component: temp,
      },
    },
  ],
};

export default customersConfig;
