import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import get from 'lodash/get';

export interface IMyUrlFieldProps {
  source: string;
}

const useStyles = makeStyles({
  link: { textDecoration: 'underline' },
  icon: {
    width: '0.5em',
    height: '0.5em',
    paddingLeft: 2,
  },
});

export default function MyUrlField({ source }: IMyUrlFieldProps) {
  const record = useRecordContext();
  const classes = useStyles();

  let value = get(record, source);

  if (value && typeof value === 'string') {
    if (!(value as string).startsWith('http')) {
      value = 'http://' + value;
    }
  }

  return record && value ? (
    <a href={value} className={classes.link} onClick={e => e.stopPropagation()} target="_blank">
      {value} <LaunchIcon className={classes.icon} />
    </a>
  ) : null;
}
