export enum Theme {
  Light = "Light",
  Dark = "Dark",
}

export type ConfigState = {
  readonly theme: Theme;
  readonly drawerToggled: boolean;
};

export const CHANGE_THEME = "CHANGE_THEME";
export const DRAWER_TOGGLED = "DRAWER_TOGGLED";

export type ChangeThemeAction = {
  readonly type: typeof CHANGE_THEME
  readonly payload: Theme
};

export type DrawerToggledAction = {
  readonly type: typeof DRAWER_TOGGLED
  readonly payload: boolean
};

export type ConfigAction
  = ChangeThemeAction | DrawerToggledAction;
