import React, { Fragment, useCallback, FC } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { push } from 'react-router-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  CardActions,
  CreateButton,
  EditButton,
  ListProps,
  TopToolbar,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BulkExportButton,
  BulkActionsToolbarProps,
  Identifier,
  ReferenceField,
} from 'react-admin';

import MyUrlField from '../../components/MyUrlField';
import ChipArrayField from '../../components/fields/ChipArrayField';

import OwnerCreate from './OwnerCreate';
import OwnerEdit from './OwnerEdit';
import OwnerListAside from './OwnerListAside';
import rowStyle from './rowStyle';
// import BulkChangeActiviteButton from './components/BulkChangeActiviteButton';
// import BulkChangeRatingButton from './components/BulkChangeRatingButton';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const OwnerFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      {/* <ReferenceInput label="User" source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput> */}

      {/* <SelectInput label="Slug" source="slug" optionText="slug" /> */}
    </Filter>
  );
};

// const OwnerListActions = ({ basePath, className }: { basePath?: string; className?: string }) => (
//   <TopToolbar className={className}>
//     <CreateButton basePath={basePath} />
//   </TopToolbar> actions={<OwnerListActions />} filters={<OwnerFilter />}
// );

const BulkActionButtons = (props: any) => (
  <Fragment>
    {/* <BulkChangeActiviteButton id="btn-1" {...props} />
    <BulkChangeRatingButton id="btn-2" {...props} /> */}

    <BulkExportButton label="Export" {...props} />
    {/* default bulk delete action */}
    {/* <BulkDeleteButton {...props} /> */}
  </Fragment>
);

export interface OwnerListProps extends ListProps {
  selectedRow?: Identifier;
}

const OwnerList: FC<OwnerListProps> = props => {
  const { selectedRow } = props;
  return (
    <Container maxWidth="xl">
      <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        // aside={<OwnerListAside />}
        filters={<OwnerFilter />}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid
          rowClick="edit"
          // @ts-ignore
          rowStyle={rowStyle(selectedRow)}
        >
          <TextField source="name" />
          <ReferenceField
            // record={contact}
            source="exportSystemInfo.exportSystemId"
            reference="exportSystems"
            basePath="/exportSystems"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="isActive" />
          <TextField source="address.city" />
          <TextField source="address.countryId" />
          <ChipArrayField source="contact.telefon" />
          <ChipArrayField source="contact.email" />
          <MyUrlField source="contact.wWW" />
          <EditButton />
        </Datagrid>
      </List>
    </Container>
  );
};

export default OwnerList;
