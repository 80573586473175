import React, { Component, FC } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    }),
  { name: 'HPBoxContainer' }
);

interface Props {}

// padding: 16px 24px;
//     margin: 12px 0;
//     background-color: white;

const BoxContainer: FC<Props> = props => {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
};

export default BoxContainer;
