import React, { MouseEventHandler, FC } from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  useTranslate,
  EditProps,
  ToolbarProps,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Layout from '../Layout';

// interface SubjectEditProps extends EditProps {
//   onCancel?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
// }

// const SubjectEditToolbar = (props: SubjectEditProps) => {
//   const { onCancel, ...rest } = props;
//   const toolbarProps: Partial<ToolbarProps> = rest;
//   const translate = useTranslate();
//   return (
//     <Toolbar {...(toolbarProps as ToolbarProps)}>
//       <SaveButton />
//       <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// };

// const SubjectEdit: FC<SubjectEditProps> = ({ onCancel, ...props }: SubjectEditProps) => (
//   <Edit title=" " {...props}>
//     <SimpleForm toolbar={<SubjectEditToolbar onCancel={onCancel} />}>
//       <TextInput source="name" validate={required()} />
//     </SimpleForm>
//   </Edit>
// );

interface Props {}

type SubjectEditProps = Props;

// const SubjectEditToolbar = (props: any) => {
//   const translate = useTranslate();
//   return (
//     <Toolbar {...props}>
//       <SaveButton />
//       <Button variant="text">{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// };

const SubjectEdit: FC<SubjectEditProps> = (props: SubjectEditProps) => (
  <Layout>
    <div>subject</div>
  </Layout>
  // <Edit title=" dsddssd" {...props}>
  //   <SimpleForm toolbar={<SubjectEditToolbar />}>
  //     <TextInput source="title" validate={required()} />
  //   </SimpleForm>
  // </Edit>
);

export default SubjectEdit;
