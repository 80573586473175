import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import OrdersNotActiveIcon from '@material-ui/icons/EventAvailableOutlined';

import { AppConfig, AppRoute } from '../../types';

const temp = () => <div />;

const ordersConfig: AppConfig = {
  // invoices: {
  // 	layout: {
  // 		config: {}
  // 	} //lazy(() => import('./invoicesEdit')),
  // },
  key: 'OrdersApp',
  name: 'Rezervace',
  icon: OrdersNotActiveIcon,

  routes: [
    {
      group: 'home',
      key: 'orders/list',
      name: 'Rezervace',
      path: '/orders',
      icon: OrdersNotActiveIcon,
      customRouteProps: {
        component: temp,
      },
    },
  ],
};

export default ordersConfig;
