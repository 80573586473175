import * as React from 'react';
import SidebarMenu from '../../components/sidebar/SidebarMenu';
import HotelIcon from '@material-ui/icons/HotelOutlined';

import { generateRoutesFromConfigs } from '../../utils';
import { AppConfig, AppRoute } from '../../types';
import HotelConfig from './HotelConfig';

export interface ILeftMenuProps {}

export default function LeftMenu(props: ILeftMenuProps) {
  let allRoutes = generateRoutesFromConfigs([HotelConfig]);

  const route: AppRoute[] = [
    {
      group: 'hotels',
      key: 'hotels/list',
      name: 'Výpis hotelů',
      path: '/hotels',
      route: '/hotels',
      icon: HotelIcon,
    },
  ];

  return <SidebarMenu routes={allRoutes}></SidebarMenu>;
}
