import { Reducer } from "redux";
import { CHANGE_APP, ChangeAppAction, AppState, AppAction} from "./types";
import {AppConfig} from '../../types'

export const initialAppState: () => AppState = () => ({
  currentApp: {} as AppConfig
});

type AppReducer = Reducer<AppState, AppAction>;
export const appReducer: AppReducer = (state = initialAppState(), action) => {
  switch (action.type) {
    case CHANGE_APP:
      return {
        ...state,
        currentApp: action.payload
      };
    default:
      return state;
  }
};
