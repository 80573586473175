import czechMessages from 'ra-language-czech';

import hotelsLocalize from '../pages/hotels/i18n/cs';
import hotelsEditLocalize from '../pages/hotels/edit/i18n/cs';
import invoicesLocalize from '../pages/invoices/i18n/cs';

const messages = {
    simple: {
        action: {
            close: 'Zavřít',
            resetViews: 'Reset pohledů',
        },
        'create-post': 'Nový post',
    },

    resources: {
        posts: {
            name: 'Post |||| Posts',
            fields: {
                average_note: 'Average note',
                body: 'Body',
                comments: 'Comments',
                commentable: 'Commentable',
                commentable_short: 'Com.',
                created_at: 'Created at',
                notifications: 'Notifications recipients',
                nb_view: 'Nb views',
                password: 'Password (if protected post)',
                pictures: 'Related Pictures',
                published_at: 'Published at',
                teaser: 'Teaser',
                tags: 'Tags',
                title: 'Title',
                views: 'Views',
                authors: 'Authors',
            },
        },
        comments: {
            name: 'Comment |||| Comments',
            fields: {
                body: 'Body',
                created_at: 'Created at',
                post_id: 'Posts',
                author: {
                    name: 'Author',
                },
            },
        },
        users: {
            name: 'Uživatel |||| Uživatelé',
            fields: {
                name: 'Jméno',
                role: 'Role',
            },
        },
        settings: {
            name: 'Nastavení',
        },
        exportSystems: {
            name: 'Exportní systém |||| Exportní systémy',
            fields: {
                name: 'Název',
                isActiveForSync: 'Povolena synchronizace dat',
                isActiveForSale: 'Povoleno obchodování hotelů/pobytů',
                initializeFactoryType: 'C# typ pro initializaci',
            },
        },
        subject: {
            name: 'Subjekt',
        },
        defaultSettings: {
            name: 'Obecné nastavení',
        },

        hotels: hotelsLocalize.resources.hotels,
        hotelsedit: hotelsEditLocalize.resources.hotelsedit,
        invoices: invoicesLocalize.resources.invoices,

        // hotels: {
        //     name: 'Hotel |||| Hotely',
        //     fields: {
        //         commands: 'Orders',
        //         first_seen: 'First seen',
        //         groups: 'Segments',
        //         last_seen: 'Last seen',
        //         last_seen_gte: 'Visited Since',
        //         name: 'Name',
        //         total_spent: 'Total spent',
        //         password: 'Password',
        //         confirm_password: 'Confirm password',
        //         stateAbbr: 'State',
        //     },
        //     filters: {
        //         last_visited: 'Naposledy zobrazeno',
        //         today: 'Dnes',
        //         this_week: 'Tento víkend',
        //         last_week: 'Minulý víkend',
        //         this_month: 'Tento měsíc',
        //         last_month: 'Minulý měsíc',
        //         earlier: 'Dříve',
        //         has_ordered: 'Již rezervované',
        //         has_newsletter: 'Has newsletter',
        //         group: 'Segment',
        //     },
        //     fieldGroups: {
        //         identity: 'Identity',
        //         address: 'Address',
        //         stats: 'Stats',
        //         history: 'History',
        //         password: 'Password',
        //         change_password: 'Change Password',
        //     },
        //     action: {
        //         bulk_change_hotel_activity: {
        //             title: 'Změnit aktivitu hotelu |||| Změnit aktivitu hotelů',
        //             activate: 'Aktivovat vybraný hotel |||| Aktivovat vybrané hotely',
        //             deactivate: 'Deaktivovat vybraný hotel |||| Deaktivovat vybrané hotely',
        //         },
        //         bulk_change_hotel_rating: {
        //             title: 'Změnit důležitost hotelu |||| Změnit důležitost hotelů'
        //         },
        //     },
        //     page: {
        //         delete: 'Delete Customer',
        //     },
        //     errors: {
        //         password_mismatch:
        //             'The password confirmation is not the same as the password.',
        //     },
        // },
    },

    post: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            miscellaneous: 'Miscellaneous',
            comments: 'Comments',
        },
        edit: {
            title: 'Post "%{title}"',
        },
        action: {
            save_and_edit: 'Save and Edit',
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
            save_with_average_note: 'Save with Note',
        },
    },
    comment: {
        list: {
            about: 'About',
        },
    },
    user: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            security: 'Security',
        },
        edit: {
            title: 'User "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    ra: {
        ...czechMessages.ra,
        action: {
            ...czechMessages.ra.action,
            search: 'Hledat',
            export: 'Export',
            unselect: 'Odznačit',
            settings: 'Nastavení',
            notifications: 'Nástěnka / Oznámení',
            close_menu: 'Zavřít menu',
            open_menu: 'Otevřít menu',
            confirm: 'Potvrdit'
        },
        navigation: {
            ...czechMessages.ra.navigation,
            page_rows_per_page: 'Záznamů na stránce'
        }
    },
};

console.log(messages);

export default messages;