import { Reducer } from "redux";
import { NotificationAction, CHANGE_THEME, NotificationState, Theme } from "./types";
import { DRAWER_TOGGLED, DrawerToggledAction } from "./types";
import { CHANGE_BANGE_VALUE, ChangeBangeValueAction } from "./types";

export const initialNotificationState: () => NotificationState = () => ({
  theme: Theme.Light,
  drawerToggled: false,
  bangeValue: 0
});

type NotificationReducer = Reducer<NotificationState, NotificationAction>;
export const notificationReducer: NotificationReducer = (state = initialNotificationState(), action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case DRAWER_TOGGLED:
      return {
        ...state,
        drawerToggled: action.payload
      };
    case CHANGE_BANGE_VALUE:
      return {
        ...state,
        bangeValue: action.payload
      };
    default:
      return state;
  }
};
