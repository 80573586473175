import React, { MouseEventHandler, FC } from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  maxLength,
  regex,
  SaveButton,
  Toolbar,
  useTranslate,
  EditProps,
  ToolbarProps,
  FormGroupContextProvider,
  useRecordContext,
  useGetOne,
  useEditContext,
  useFormGroup,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import clsx from 'clsx';
import { Box, CardContent, Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import ShopIcon from '@material-ui/icons/Shop';
import { makeStyles } from '@material-ui/core/styles';
import { Owner } from './types';
// type OwnerEditProps = {
//   onCancel: () => void;
// };

// const OwnerEditToolbar = ({ onCancel, ...rest }: Partial<ToolbarProps> & OwnerEditProps) => {
//   const translate = useTranslate();
//   return (
//     <Toolbar {...(rest as unknown)}>
//       <SaveButton />
//       <Button onClick={onCancel} variant="text">
//         {translate('ra.action.cancel')}
//       </Button>
//     </Toolbar>
//   ); toolbar={<OwnerEditToolbar onCancel={onCancel} />}
// };

const useStyles = makeStyles({
  root: {},
  divider: {
    width: '1px',
    height: '28px',
  },
  MuiInputAdornmentPositionEnd: {
    marginLeft: '8px',
  },
  adornedEnd: {
    paddingRight: '0',
  },
  rightButton: {
    padding: '7px',
    borderRadius: '0',
  },
  overrides: {
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0',
      },
    },
  },
  inline: {
    display: 'inline-block',
    marginLeft: '1em',
    '&.first-child': {
      marginLeft: 0,
    },
  },
});

const validateSlugValue = regex(/^[a-z][a-z0-9\-]+$/, 'Must be a valid Slug [a-z0-9] or -');

const OwnerEdit: FC<EditProps> = (props: EditProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  // const { record, loaded } = useEditContext<Owner>(props);
  // if (!loaded || !record) return <div>1.</div>;

  // const record = useRecordContext();
  //console.log(record);

  // const { data, loading, error } = useGetOne('Owners', !!props.id ? props.id : '1');
  // if (loading) {
  //   return <div>skljsdlj</div>;
  // }
  // if (error) {
  //   return <p>ERROR</p>;
  // }

  //console.log(data);

  return (
    <Container>
      <Edit title="Editace Owneru" {...props}>
        <SimpleForm component={CustomLayout}>
          <TextInput source="name" fullWidth validate={required()} />
          <ReferenceInput source="exportSystemInfo.exportSystemId" reference="exportSystems">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="slug"
            variant="outlined"
            fullWidth
            validate={[required(), maxLength(255), validateSlugValue]}
            style={{ paddingRight: '0!important' }}
            InputProps={{
              classes: {
                adornedEnd: classes.adornedEnd,
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.adornedEnd}>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Tooltip title={translate('ra.action.refresh')}>
                    <IconButton className={classes.rightButton}>
                      <RefreshOutlined />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <CustomDivider />
          <TextInput source="category" formClassName={clsx(classes.inline, 'first-child')} validate={required()} />
          <TextInput source="destination" formClassName={classes.inline} validate={required()} />
          <CustomDivider />

          <AccordionSectionTitle name="address">{translate('hp.address')}</AccordionSectionTitle>
          <TextInput source="address.Street" label="Street" fullWidth helperText={false} validate={required()} />
          <TextInput
            source="address.City"
            label="City"
            formClassName={clsx(classes.inline, 'first-child')}
            validate={required()}
          />
          <TextInput
            source="address.PostalCode"
            label="Postal Code"
            formClassName={clsx(classes.inline)}
            validate={required()}
          />
          {/* <TextInput source="address.stateAbbr" formClassName={classes.inline} /> */}
          <CustomDivider />
        </SimpleForm>
      </Edit>
    </Container>
  );
};

const AccordionSectionTitle = ({ children, name }: { children: any; name: string }) => {
  const formGroupState = useFormGroup(name);

  return (
    <Typography color={formGroupState.invalid && formGroupState.dirty ? 'error' : 'inherit'}>{children}</Typography>
  );
};

const CustomLayout = (props: any) => (
  <CardContent>
    <Box display="flex">
      <Box paddingTop={1}>
        <Avatar>
          <ShopIcon />
        </Avatar>
      </Box>
      <Box ml={2} flex="1" maxWidth={796}>
        {props.children}
      </Box>
    </Box>
  </CardContent>
);

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

export default OwnerEdit;
