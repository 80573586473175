import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configReducer } from './store/reducer';
import { ConfigState } from './store/types';
import { drawerToggle } from './store/actions';
import { AppState } from '../../store/types';

const drawerWidth = 350;
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      width: drawerWidth,
      top: 50,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      top: 50,
    },
    drawerPaper: {
      width: drawerWidth,
      top: 50,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    backdrop: {
      backgroundColor: 'transparent',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

function SettingsPanel() {
  const dispatch = useDispatch();

  const isdrawerToggled = useSelector<AppState, boolean>(({ config }) => config.drawerToggled);

  const classes = useStyles();
  const [checked, setChecked] = useState('notifications');

  const handleToggle = (value: any) => () => {
    // const currentIndex = checked.indexOf(value);
    // const newChecked = [...checked];
    // if (currentIndex === -1) {
    //   newChecked.push(value);
    // } else {
    //   newChecked.splice(currentIndex, 1);
    // }
    // setChecked(newChecked);
  };

  //   useEffect(() => {
  //     dispatch(getData());
  //   }, [dispatch]);

  return (
    <SwipeableDrawer
      classes={{ paper: classes.drawerPaper }}
      open={isdrawerToggled}
      anchor="right"
      disablePortal
      className={classes.drawer}
      hideBackdrop
      BackdropProps={{ classes: { root: classes.backdrop } }}
      onOpen={ev => {}}
      onClose={ev => dispatch(drawerToggle(!isdrawerToggled))}
      disableSwipeToOpen
    >
      <div className={classes.drawerContainer}>
        <ListSubheader component="div">Today</ListSubheader>

        <div className="mb-0 py-16 px-24">
          <Typography className="mb-12 text-32" color="textSecondary">
            {format(new Date(), 'eeee')}
          </Typography>
          <div className="flex">
            <Typography className="leading-none text-32" color="textSecondary">
              {format(new Date(), 'dd')}
            </Typography>
            <Typography className="leading-none text-16" color="textSecondary">
              th
            </Typography>
            <Typography className="leading-none text-32" color="textSecondary">
              {format(new Date(), 'MMMM')}
            </Typography>
          </div>
        </div>
        <Divider />
        <List>
          <ListSubheader component="div">Events</ListSubheader>
          {/* {data &&
            data.events.map(event => (
              <ListItem key={event.id}>
                <ListItemText primary={event.title} secondary={event.detail} />
              </ListItem>
            ))} */}
        </List>
        <Divider />
        <List>
          <ListSubheader component="div">Notes</ListSubheader>
          {/* {data &&
            data.notes.map(note => (
              <ListItem key={note.id}>
                <ListItemText primary={note.title} secondary={note.detail} />
              </ListItem>
            ))} */}
        </List>
        <Divider />
        <List>
          <ListSubheader component="div">Quick Settings</ListSubheader>
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>notifications</Icon>
            </ListItemIcon>
            <ListItemText primary="Notifications" />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                onChange={handleToggle('notifications')}
                checked={checked.indexOf('notifications') !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>cloud</Icon>
            </ListItemIcon>
            <ListItemText primary="Cloud Sync" />
            <ListItemSecondaryAction>
              <Switch
                color="secondary"
                onChange={handleToggle('cloudSync')}
                checked={checked.indexOf('cloudSync') !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>brightness_high</Icon>
            </ListItemIcon>
            <ListItemText primary="Retro Thrusters" />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                onChange={handleToggle('retroThrusters')}
                checked={checked.indexOf('retroThrusters') !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
}

//export default withReducer('config', reducer)(memo(SettingsPanel));

export default memo(SettingsPanel);
