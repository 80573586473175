import React, { Fragment, useCallback, FC } from 'react';
import Box from '@material-ui/core/Box';
import { ListProps, useTranslate } from 'react-admin';
import BulkChangeDataButton, { MenuItemProps } from '../../../components/buttons/BulkChangeDataButton';

const changeActivityMenu: MenuItemProps[] = [
  {
    id: '1',
    label: 'Odeslat e-mail na hotel',
    onClick: () => console.log('klik Prodeje'),
  },
  {
    id: '2',
    label: '...',
    onClick: () => console.log('klik ...'),
  },
];

const handleChange = (data: MenuItemProps) => {
  console.log(data);
};

const NextMenuButton = (props: any) => {
  var translate = useTranslate();
  return (
    <BulkChangeDataButton
      id="btn-1"
      label="..."
      {...props}
      variant="contained"
      menu={changeActivityMenu}
      onChange={data => handleChange(data)}
    />
  );
};

export default NextMenuButton;
