import React, { FC, MouseEvent } from 'react';
import {
  ReferenceManyField,
  ShowContextProvider,
  useShowContext,
  useShowController,
  Pagination as RaPagination,
  TextField,
  UrlField,
  SimpleShowLayout,
  Toolbar,
  useTranslate,
  ShowProps,
  ToolbarProps,
  useRecordContext,
  useGetOne,
  ReferenceField,
  BooleanInput,
  Translate,
  Edit,
  SimpleForm,
} from 'react-admin';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import RoomOutlined from '@material-ui/icons/RoomOutlined';
import LocationIcon from '@material-ui/icons/LocationCityOutlined';

import HotelAddressEditDialog from './HotelAddressEditDialog';
import HotelActivityInput from './HotelActivityInput';

import { Hotel } from '../types';
import { AnyMxRecord } from 'dns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
    },
    inline: {},
    avatar: {
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  })
);

const AddressDetail = ({ record, translate }: { record?: Hotel; translate: Translate }) => {
  return (
    <>
      <Box flexDirection="row">
        <TextField source="address.street" label={translate('resources.hotels.fields.address.street')} />
        <Typography variant="body1" component="span">
          {', '}
        </Typography>
        <TextField source="address.postalCode" label={translate('resources.hotels.fields.address.postalCode')} />
        <Typography variant="body1" component="span">
          {' '}
        </Typography>
        <TextField source="address.city" label={translate('resources.hotels.fields.address.city')} />
      </Box>
      <Box flexDirection="row">
        <Typography variant="subtitle2" component="span">
          GPS loc.:{' '}
        </Typography>
        <TextField source="address.latitude" label={translate('resources.hotels.fields.address.latitude')} />
        <Typography variant="body1" component="span">
          {', '}
        </Typography>
        <TextField source="address.longitude" label={translate('resources.hotels.fields.address.longitude')} />
      </Box>
    </>
  );
};

const HotelAddress: FC<ShowProps> = props => {
  const { loading, ...context } = useShowContext<Hotel>(props);
  const { record } = context;
  const classes = useStyles();
  const translate = useTranslate();

  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="edit address" className={classes.avatar}>
              <RoomOutlined />
            </Avatar>
          }
          action={
            <Box flexDirection="row">
              <IconButton aria-label="settings" onClick={() => setShowDialog(true)}>
                <EditIcon />
              </IconButton>
            </Box>
          }
          title={<Typography variant="subtitle1">{translate(`resources.hotelsedit.fields.address`)}</Typography>}
          subheader={<AddressDetail record={record} translate={translate} />}
        />
      </Card>
      <HotelAddressEditDialog
        {...props}
        onChange={() => setShowDialog(false)}
        onClose={() => setShowDialog(false)}
        open={showDialog}
        className=""
      />
    </>
  );
};

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

export default HotelAddress;
