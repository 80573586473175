
export const messages = {
    resources: {
        hotels: {
            name: 'Hotel |||| Hotely',
            fields: {
                name: 'Název',
                ownerId: 'Vlastník',
                category: 'Kategorie',
                destination: 'Oblast',
                price: 'Cena od',
                slug: 'Slug',
                address: {
                    section: 'Adresa',
                    street: 'Ulice',
                    city: 'Město',
                    postalCode: 'PSČ'
                },
                checkIn: 'Check in',
                checkOut: 'Check out',
                ratting: 'Hodnocení'
            },
            filters: {
                last_visited: 'Naposledy zobrazeno',
                today: 'Dnes',
                this_week: 'Tento víkend',
                last_week: 'Minulý víkend',
                this_month: 'Tento měsíc',
                last_month: 'Minulý měsíc',
                earlier: 'Dříve',
                has_ordered: 'Již rezervované',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identity',
                address: 'Address',
                stats: 'Stats',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            action: {
                bulk_change_hotel_activity: {
                    title: 'Změnit aktivitu hotelu |||| Změnit aktivitu hotelů',
                    activate: 'Aktivovat vybraný hotel |||| Aktivovat vybrané hotely',
                    deactivate: 'Deaktivovat vybraný hotel |||| Deaktivovat vybrané hotely',
                },
                bulk_change_hotel_rating: {
                    title: 'Změnit důležitost hotelu |||| Změnit důležitost hotelů'
                },
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
    },
};



export default messages;