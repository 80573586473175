import * as React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import lodashGet from 'lodash/get';
// @ts-ignore
import { useMediaQuery, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import MenuOpen from '@material-ui/icons/MenuOpen';
// @ts-ignore
import classnames from 'classnames';
import { useGetResourceLabel, getResources, ReduxState } from 'ra-core';

import {
  MenuItemLink,
  DashboardMenuItem,
  useTranslate,
  toggleSidebar,
  useRecordContext,
  linkToRecord,
} from 'react-admin';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { AppRoute } from '../../../types';
export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: '1.5em',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em',
      },
    },
    header: {
      marginLeft: theme.spacing(2),
      marginBottom: '0.5em',
      fontSize: '2rem',
      display: 'flex',
      flexDirection: 'row',

      '& > svg': {
        fontSize: '26px',
        alignSelf: 'center',
        marginLeft: '-4px',
      },
    },
    title: {
      marginLeft: theme.spacing(2),
      alignSelf: 'center',
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
    menubutton: {
      position: 'absolute',
      right: '0px',
      top: '10px',
      fontSize: '28px',
      backgroundColor: theme.palette.sidebar.main,
      '& svg': {
        fontSize: '26px',
      },
    },
  }),
  { name: 'RaMenu' }
);

const Menu: FC<MenuProps> = props => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    routes,
    selectedRoute,
    ...rest
  } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const getResourceLabel = useGetResourceLabel();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const record = useRecordContext();
  console.log(routes);
  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} dense={dense} sidebarIsOpen={open} />}
      {routes
        .filter(r => !!!r.invisible)
        .map(resource => {
          const link =
            record && record.id && resource.path.includes(':id')
              ? resource.path.replace(':id', record.id as string)
              : resource.path;
          return (
            <MenuItemLink
              key={resource.key}
              to={{
                pathname: link,
                state: { _scrollToTop: true },
              }}
              // isActive={(match, location) => {
              //   console.log(match, location);

              //   if (!match) {
              //     return false;
              //   }

              //   // only consider an event active if its event id is an odd number
              //   // const eventID = parseInt(match.params.eventID);
              //   // return !isNaN(eventID) && eventID % 2 === 1;
              //   return true;
              // }}
              primaryText={getResourceLabel(resource.name, 2)}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              dense={dense}
              sidebarIsOpen={open}
            />
          );
        })}
      {/* {isXSmall && logout} */}
    </div>
  );
};

export interface MenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  onMenuClick?: () => void;
  routes: AppRoute[];
  selectedRoute: AppRoute | null;
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
