
export const messages = {
    resources: {
        hotelsedit: {
            name: 'Hotel',
            fields: {
                checkIn: 'Check in',
                checkOut: 'Check out',
                numberOfPax: 'Pevné postele',
                numberOfExtrabed: 'Přistýlky',
                numberOfChildExtrabed: 'Dětské postýlky',
                address: 'Adresa'
            },

            action: {
                bulk_change_hotel_activity: {
                    title: 'Změnit aktivitu hotelu |||| Změnit aktivitu hotelů',
                    activate: 'Aktivovat vybraný hotel |||| Aktivovat vybrané hotely',
                    deactivate: 'Deaktivovat vybraný hotel |||| Deaktivovat vybrané hotely',
                },
                bulk_change_hotel_rating: {
                    title: 'Změnit důležitost hotelu |||| Změnit důležitost hotelů'
                },
                deactive: {
                    label: 'Deaktivovat',
                    confirm: {
                        title: 'Deaktivace hotelu',
                        content: 'Opravdu chcete deaktivovat tento hotel?'
                    }
                },
                active: {
                    label: 'Aktivovat',
                    confirm: {
                        title: 'Aktivace hotelu',
                        content: 'Opravdu chcete aktivovat tento hotel?'
                    }
                }

            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
    },
};



export default messages;