import {AppConfig} from '../../types'

export type AppState = {
  readonly currentApp?: AppConfig;
};

export const CHANGE_APP = "CHANGE_APP";

export type ChangeAppAction = {
  readonly type: typeof CHANGE_APP
  readonly payload: AppConfig
};

export type AppAction
  = ChangeAppAction;
