import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SubjectIcon from '@material-ui/icons/SubjectOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';

import { AppConfig, AppRoute } from '../../types';
import SettingsEdit from './SettingsEdit';
import SubjectEdit from './subject/SubjectEdit';
import ExportSystem from './export-systems';

const SettingsConfig: AppConfig = {
  // settings: {
  // 	layout: {
  // 		config: {}
  // 	} //lazy(() => import('./SettingsEdit')),
  // },

  key: 'SettingsApp',
  name: 'Nastavení',
  icon: SyncOutlinedIcon,
  routes: [
    {
      group: 'settings',
      key: 'settings/exportsystems',
      name: 'exportSystems',
      path: '/exportsystems',
      icon: SyncOutlinedIcon,
      resourceRouteProps: {
        name: 'exportSystems',
        ...ExportSystem,
      },
    },
    {
      group: 'settings',
      key: 'settings/default-settings',
      name: 'defaultSettings',
      path: '/settings/default-settings',
      route: '/settings/default-settings',
      icon: SettingsIcon,
      customRouteProps: {
        component: SettingsEdit,
      },
    },
    {
      group: 'settings',
      key: 'settings/subject',
      name: 'subject',
      path: '/settings/subject',
      route: '/settings/subject',
      icon: SubjectIcon,
      customRouteProps: {
        component: SubjectEdit,
      },
    },
    {
      group: 'settings',
      key: 'settings',
      name: 'settings',
      path: '/settings',
      route: '/settings',
      invisible: true,
      customRouteProps: {
        render: (props: any) => {
          console.log(props);
          debugger;
          return <Redirect to="/exportsystems" />;
        },
      },
    },
  ],
};

export default SettingsConfig;
