// import React from 'react';
// import Box from '@material-ui/core/Box';
// import {Theme} from '@material-ui/core';
// import {makeStyles} from '@material-ui/styles';

// import HomeIcon from '@material-ui/icons/Home';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import { AppRoute } from '../../types';

// const useClasses = makeStyles(
//     theme => ({
//         main:{

//         }
// }), { name: 'HPBreadScrumb' });

// interface BreadScrumbProps {
//   routes: AppRoute[];
// }

// const BreadScrumb = (props: BreadScrumbProps) => {
//   const { routes } = props;

//   if (!!!routes || routes.length === 0) {
//     return null;
//   }

//   return (
//     <Box display="flex" flexDirection="row">
//       <a href="/">
//         <HomeIcon />
//       </a>
//       {routes.map((route: AppRoute) => (
//         <>
//           <ChevronRightIcon />
//           <a href={route.path}>{route.name}</a>
//         </>
//       ))}
//     </Box>
//   );
// };

// export default BreadScrumb;

import React from 'react';
import Link, { LinkProps } from '@material-ui/core/Link';
//import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { emphasize, withStyles, Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppRoute } from '../../types';

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    //  padding: 12px 24px;
    //border-bottom: 1px solid #e4e4e4;
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '0.8rem',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      marginBottom: theme.spacing(1),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  //event.preventDefault();
  //console.info('You clicked a breadcrumb.');
}

interface BreadScrumbProps {
  routes?: AppRoute[];
}

export default function CustomizedBreadcrumbs(props: BreadScrumbProps) {
  const classes = useStyles();
  const { routes } = props;

  if (!!!routes || routes.length === 0) {
    return null;
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <StyledBreadcrumb
        component={RouterLink}
        to="/"
        label="Home"
        icon={<HomeIcon fontSize="small" />}
        onClick={handleClick}
      />

      {routes.map((route: AppRoute) => (
        <StyledBreadcrumb component={RouterLink} to={route.path} label={route.name} onClick={handleClick} />
      ))}

      {/* <StyledBreadcrumb component={RouterLink} to="/hotels" label="Catalog" onClick={handleClick} />
      <StyledBreadcrumb
        label="Accessories"
        deleteIcon={<ExpandMoreIcon />}
        onClick={handleClick}
        onDelete={handleClick}
      /> */}
    </Breadcrumbs>
  );
}
