import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  email,
  Button,
  SaveButton,
  TextInput,
  number,
  NumberInput,
  DateInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FormWithRedirectRenderProps,
  FormWithRedirectSave,
  Record,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  regex,
  useTranslate,
  EditBase,
  EditProps,
  useEditContext,
  RedirectionSideEffect,
  OnSuccess,
  OnFailure,
  useRefresh,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';

import { makeStyles } from '@material-ui/core/styles';

import { Hotel } from '../types';

const useStyles = makeStyles({
  root: {},
  inline: {
    display: 'inline-block',
    marginLeft: '1em',
    '&.first-child': {
      marginLeft: 0,
    },
  },
});

interface Props extends EditProps {
  open: boolean;
  onChange: () => void;
  onClose: () => void;
  className: string;
}

function HotelAddressEditDialog({ onChange, className, open, onClose }: Props) {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { save, record } = useEditContext();

  const handleSubmit = async (
    data: Partial<Hotel>,
    redirectTo: RedirectionSideEffect,
    options?: { onSuccess?: OnSuccess; onFailure?: OnFailure }
  ) => {
    if (save && data.address) {
      const tempData: Partial<Hotel> = {};

      tempData.address = {
        street: data.address.street,
        city: data.address.city,
        postalCode: data.address.postalCode,
        latitude: data.address.latitude,
        longitude: data.address.longitude,
      };

      save(tempData, redirectTo, {
        onSuccess: (response: any) => {
          notify(
            'ra.notification.updated',
            'info',
            {
              smart_count: 1,
            },
            true
          );
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
          refresh();
        },
      });
    }
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose} aria-label="Editace adresy hotelu">
        <DialogTitle>Editace adresy hotelu</DialogTitle>

        <FormWithRedirect
          // resource="hotels"
          save={handleSubmit}
          redirect="show"
          record={record as any}
          render={({ handleSubmitWithRedirect, pristine, saving, valid }: FormWithRedirectRenderProps) => (
            <form>
              <DialogContent>
                <TextInput source="address.street" fullWidth validate={required()} />
                <TextInput source="address.city" fullWidth validate={required()} />
                <TextInput source="address.postalCode" fullWidth validate={required()} />
                <NumberInput source="address.latitude" fullWidth validate={(required(), number())} />
                <NumberInput source="address.longitude" fullWidth validate={(required(), number())} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose}>
                  {/* // disabled={loading} */}
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  disabled={pristine || !valid} //|| loading
                  saving={saving}
                />
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
}

export const HotelEdit = (props: Props) => (
  <EditBase
    {...props}
    transform={(data: Hotel) => ({
      ...data,
      last_seen: new Date(),
    })}
  >
    <HotelAddressEditDialog {...props} />
  </EditBase>
);

export default HotelEdit;
