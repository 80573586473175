import {DataProvider} from 'react-admin'

// @ts-ignore
import fakerestDataProvider from 'ra-data-fakerest';
import { cacheDataProviderProxy } from 'react-admin';
import data from './data'

// A function decorating a dataProvider for handling user profiles
const addUserProfileOverrides = (dataProvider: DataProvider) => ({
  ...dataProvider,
  getUserProfile() {
    const profile = localStorage.getItem("userProfile");
    if (!profile) {
      return Promise.resolve({ data: {} });
    }
    const data = JSON.parse(profile);

    return Promise.resolve({ data });
  },
  async updateUserProfile({ data }: any) {
    // Convert a newly uploaded file to b64
    const avatar = await (data.avatar.rawFile instanceof File
      ? convertFileToBase64(data.avatar)
      : data.avatar);

    localStorage.setItem(
      "userProfile",
      JSON.stringify({
        ...data,
        id: "unique_id",
        avatar
      }) 
    );
    return Promise.resolve({ data });
  }
});

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

// export default addUserProfileOverrides(
//   jsonServerProvider("https://jsonplaceholder.typicode.com")
// );

// debugger;
// export default addUserProfileOverrides(
//   simpleRestProvider("http://localhost:1337")
// );

//const prom = Promise.resolve(simpleRestProvider);
const baseDataProvider = fakerestDataProvider(data(), true);


export const dataProvider = new Proxy(baseDataProvider, {  
    get: (target, name: string) => (resource: string, params: any) =>
     {    
    return new Promise(resolve =>
            setTimeout(
                () => resolve(baseDataProvider[name](resource, params)),
                300
            )
        )
   }

});


//const dataProvider = fakerestDataProvider(data, true);
//const uploadCapableDataProvider = addUploadFeature(dataProvider);
// const sometimesFailsDataProvider = new Proxy(uploadCapableDataProvider, {
//     get: (target, name) => (resource, params) => {
//         // set session_ended=true in localStorage to trigger an API auth error
//         if (localStorage.getItem('session_ended')) {
//             const error = new Error('Session ended') as ResponseError;
//             error.status = 403;
//             return Promise.reject(error);
//         }
//         // add rejection by type or resource here for tests, e.g.
//         // if (name === 'delete' && resource === 'posts') {
//         //     return Promise.reject(new Error('deletion error'));
//         // }
//         if (
//             resource === 'posts' &&
//             params.data &&
//             params.data.title === 'f00bar'
//         ) {
//             return Promise.reject(new Error('this title cannot be used'));
//         }
//         return uploadCapableDataProvider[name](resource, params);
//     },
// });
// const delayedDataProvider = new Proxy(sometimesFailsDataProvider, {
//     get: (target, name) => (resource, params) =>
//         new Promise(resolve =>
//             setTimeout(
//                 () =>
//                     resolve(sometimesFailsDataProvider[name](resource, params)),
//                 300
//             )
//         ),
// });

interface ResponseError extends Error {
    status?: number;
}

