import React from "react";
import { List, Datagrid, TextField, EmailField } from "react-admin";
import { MyUrlField } from "../../components";

interface IProps {}

const UserList = (props: IProps): React.ReactElement => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        {/* <TextField source="id" /> */}
        <TextField source="name" />
        <TextField source="username" />
        <EmailField source="email" />
        <TextField source="address.street" />
        <TextField source="phone" />
        <MyUrlField source="website" />
        <TextField source="company.name" />
      </Datagrid>
    </List>
  );
};

export { UserList };
