import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  email,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FormWithRedirectRenderProps,
  FormWithRedirectSave,
  Record,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  regex,
  useTranslate,
  EditBase,
  EditProps,
  useEditContext,
  RedirectionSideEffect,
  OnSuccess,
  OnFailure,
  useRefresh,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';

import { makeStyles } from '@material-ui/core/styles';

import { Hotel } from '../types';

const useStyles = makeStyles({
  root: {},
  divider: {
    width: '1px',
    height: '28px',
  },
  MuiInputAdornmentPositionEnd: {
    marginLeft: '8px',
  },
  adornedEnd: {
    paddingRight: '0',
  },
  rightButton: {
    padding: '7px',
    borderRadius: '0',
  },
  overrides: {
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0',
      },
    },
  },
  inline: {
    display: 'inline-block',
    marginLeft: '1em',
    '&.first-child': {
      marginLeft: 0,
    },
  },
});

interface Props extends EditProps {
  open: boolean;
  onChange: () => void;
  onClose: () => void;
  className: string;
}

function HotelEditContent({ onChange, className, open, onClose }: Props) {
  //const [showDialog, setShowDialog] = useState(false);
  //const [create, { loading }] = useCreate('users');
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { save, record } = useEditContext();
  //const form = useForm();

  // const handleClick = () => {
  //   setShowDialog(true);
  // };

  // const handleCloseClick = () => {
  //   setShowDialog(false);
  // };

  // const handleSubmit = async (values: any) => {
  //   create(
  //     { payload: { data: values } },
  //     {
  //       onSuccess: ({ data }: { data: Partial<Record> }) => {
  //         setShowDialog(false);
  //         // Update the comment form to target the newly created post
  //         // Updating the ReferenceInput value will force it to reload the available posts
  //         // form.change('hotelId', data.id);
  //         onChange();
  //       },
  //       onFailure: ({ error }: any) => {
  //         notify(error.message, 'error');
  //       },
  //     }
  //   );
  // };

  const handleSubmit = async (
    data: Partial<Hotel>,
    redirectTo: RedirectionSideEffect,
    options?: { onSuccess?: OnSuccess; onFailure?: OnFailure }
  ) => {
    if (save) {
      const tempData: Partial<Hotel> = {
        name: data.name,
        category: data.category,
        ownerId: data.ownerId,
        slug: data.slug,
      };

      save(tempData, redirectTo, {
        onSuccess: (response: any) => {
          notify(
            'ra.notification.updated',
            'info',
            {
              smart_count: 1,
            },
            true
          );
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
          refresh();
        },
      });
    }
  };

  const handleSubmit1 = async ({ data, redirectTo, options }: { data: any; redirectTo: any; options: any }) => {
    // save(data, undefined, {
    //   onSuccess: ({ data }: { data: Partial<Record> }) => {
    //     onChange();
    //   },
    //   onFailure: ({ error }: any) => {
    //     notify(error.message, 'error');
    //   },
    // });

    console.log(data, redirectTo, options);
  };

  const validateSlugValue = regex(/^[a-z][a-z0-9\-]+$/, 'Must be a valid Slug [a-z0-9] or -');

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose} aria-label="Editace hotelu">
        <DialogTitle>Editace hotelu</DialogTitle>

        <FormWithRedirect
          // resource="hotels"
          save={handleSubmit}
          redirect="show"
          record={record as any}
          render={({ handleSubmitWithRedirect, pristine, saving, valid }: FormWithRedirectRenderProps) => (
            <form>
              <DialogContent>
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="category" fullWidth validate={required()} />
                {/* <DateInput source="created_at" /> */}
                <ReferenceInput source="ownerId" reference="owners" fullWidth>
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <TextInput
                  source="slug"
                  fullWidth
                  validate={[required(), maxLength(255), validateSlugValue]}
                  style={{ paddingRight: '0!important' }}
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adornedEnd,
                    },
                    endAdornment: (
                      <InputAdornment position="end" className={classes.adornedEnd}>
                        <Divider orientation="vertical" className={classes.divider} />
                        <Tooltip title={translate('ra.action.refresh')}>
                          <IconButton className={classes.rightButton}>
                            <RefreshOutlined />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose}>
                  {/* // disabled={loading} */}
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  disabled={pristine || !valid} //|| loading
                  saving={saving}
                />
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
}

export const HotelEdit = (props: Props) => (
  <EditBase
    {...props}
    transform={(data: Hotel) => ({
      ...data,
      last_seen: new Date(),
    })}
  >
    <HotelEditContent {...props} />
  </EditBase>
);

export default HotelEdit;
