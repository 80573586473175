import {Owner} from './types';

const owners: Array<Owner> = [
  {
    id: 1,
    name: 'Euroagentur Hotels & Travel a.s.',
    exportSystemInfo:{exportSystemId:1},
    isActive: true,
   address:{
    street:'street',
    postalCode:'postalCode',
    city:'city',
    countryId:'CR'
   },
   contact:{
 telefon:['654645', '6546464'],
    fax:['654645'],
    email:['654645@aa.com', 'aa@aa.com'],
    wWW:'www.euroagentur.com'
   },
   corporateInfo:{
        coId : '123456',
        vAT : 'CZ654987',
        BankName : 'FIO',
        BankAccount : '654645321',
        BankCode : '7100',
        SWIFT : '654321615',
        IBAN : '9876546547100',
   }
  } 
];

export default  owners;
