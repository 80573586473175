import * as React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
// @ts-ignore
import { useMediaQuery, Theme } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import classnames from 'classnames';
import { ReduxState } from 'ra-core';

import { useTranslate } from 'react-admin';
import { AppRoute } from '../../types';

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0',
      padding: '0',
      paddingRight: '0.2rem',
      //backgroundColor: theme.palette.sidebar.main,
      backgroundColor: theme.palette.background.paper,
      minHeight: 'calc(100vh - 50px)',

      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '0',
      },

      [theme.breakpoints.between('sm', 'md')]: {
        position: 'fixed',
        zIndex: 2001,
        left: '5.0rem',
        top: '48px',
        minHeight: 'calc(100vh - 50px)',
      },

      '&::before': {
        background: 'linear-gradient(to right,transparent,#aaa)',
        content: '""',
        height: '100%',
        opacity: '.3',
        pointerEvents: 'none',
        position: 'absolute',
        right: '0',
        width: '.2rem',
        zIndex: '2',
      },
    },

    inner: {
      padding: '10px',
    },
    open: {
      width: 'auto',
      display: 'flex',
    },
    closed: {
      width: '0',
      display: 'none',
    },
    small: {
      position: 'fixed',
      zIndex: 2001,
      left: '5.0rem',
      top: '0',
      minHeight: '100vh',
    },
    popup: {
      position: 'fixed',
      zIndex: 2002,
      left: '5.0rem',
      top: '48px',
      minHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.only('xs')]: {
        top: '0',
        minHeight: '100vh',
      },
    },
  }),
  { name: 'HPSidebarContainer' }
);

const SidebarContainer: FC<SidebarContainerProps> = props => {
  const { classes: classesOverride, children, className, dense, popup, ...rest } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();

  return (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.popup]: open && popup,
          [classes.small]: open && !popup && isXSmall,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export interface SidebarContainerProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  popup?: boolean;
}

SidebarContainer.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  popup: PropTypes.bool,
};

SidebarContainer.defaultProps = {
  popup: false,
};

export default SidebarContainer;
