import React, { MouseEventHandler, FC } from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  useTranslate,
  EditProps,
  ToolbarProps,
} from 'react-admin';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import { AnyARecord } from 'dns';
// import PageSimple from '../../containers/PageSimple';
// import SettingsHeader from './SettingsHeader';
// import SettingsSidebarContent from './SettingsSidebarContent';
// import SettingsSidebarHeader from './SettingsSidebarHeader';

import PageSimple from '../../containers/PageSimple';
import Header from '../../containers/PageSimple/Partials/Header';
import SidebarContent from '../../containers/PageSimple/Partials/SidebarContent';
import SidebarHeader from '../../containers/PageSimple/Partials/SidebarHeader';

import { generateRoutesFromConfigs } from '../../utils';
import { AppConfig } from '../../types';
import SettingsConfig from './SettingsConfig';
import Layout from './Layout';

// interface SettingsEditProps extends EditProps {
//   onCancel?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
// }

// const SettingsEditToolbar = (props: SettingsEditProps) => {
//   const { onCancel, ...rest } = props;
//   const toolbarProps: Partial<ToolbarProps> = rest;
//   const translate = useTranslate();
//   return (
//     <Toolbar {...(toolbarProps as ToolbarProps)}>
//       <SaveButton />
//       <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// };

// const SettingsEdit: FC<SettingsEditProps> = ({ onCancel, ...props }: SettingsEditProps) => (
//   <Edit title=" " {...props}>
//     <SimpleForm toolbar={<SettingsEditToolbar onCancel={onCancel} />}>
//       <TextInput source="name" validate={required()} />
//     </SimpleForm>
//   </Edit>
// );

interface Props {}

type SettingsEditProps = Props;

// const SettingsEditToolbar = (props: any) => {
//   const translate = useTranslate();
//   return (
//     <Toolbar {...props}>
//       <SaveButton />
//       <Button variant="text">{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// };

// const SettingsEdit: FC<any> = (props: any) => {
//   return (
//     <SimpleForm>
//       <TextInput source="title" validate={required()} />
//     </SimpleForm>
//   );
// };

// function SettingsEdit: FC<any> = (props: any) => {

//   const dispatch = useDispatch();

//   const pageLayout = useRef(null);
//   const routeParams = useParams();

//   // useDeepCompareEffect(() => {
//   //   dispatch(getContacts(routeParams));
//   //   dispatch(getUserData());
//   // }, [dispatch, routeParams]);

//   // return (
//   //   <>
//   //     <PageSimple
//   //       // classes={{
//   //       //   contentWrapper: 'p-0 sm:p-24 h-full',
//   //       //   content: 'flex flex-col h-full',
//   //       //   leftSidebar: 'w-256 border-0',
//   //       //   header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
//   //       //   wrapper: 'min-h-0',
//   //       // }}
//   //       // header={<ContactsHeader pageLayout={pageLayout} />}
//   //       // content={<ContactsList />}
//   //       // leftSidebarContent={<ContactsSidebarContent />}
//   //       sidebarInner
//   //       ref={pageLayout}
//   //       innerScroll
//   //     />
//   //     // <ContactDialog />
//   //   </>
//   // );
// }

//function SettingsEdit: FC<any> = (props: any) => {}

const SettingsEdit: FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const pageLayout = useRef(null);
  const routeParams = useParams();

  const edit = (
    <SimpleForm>
      <TextInput source="title" validate={required()} />
    </SimpleForm>
  );

  let allRoutes = generateRoutesFromConfigs([SettingsConfig]);
  const header = () => <Header pageLayout={pageLayout}>fds;lks;kl;dsk;dk</Header>;
  return (
    <>
      {/* <PageSimple
        classes={{
          contentWrapper: 'p-0 sm:p-24 h-full',
          content: 'flex flex-col h-full',
          leftSidebar: 'w-256 border-0',
          header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
          wrapper: 'min-h-0',
        }}
        header={<SettingsHeader pageLayout={pageLayout} />}
        content={edit}
        leftSidebarHeader={<SettingsSidebarHeader />}
        leftSidebarContent={<SettingsSidebarContent />}
        sidebarInner
        ref={pageLayout}
        innerScroll
      /> */}

      {/* <PageSimple
        // classes={{
        //   contentWrapper: 'p-0 sm:p-24 h-full',
        //   content: 'flex flex-col h-full',
        //   leftSidebar: 'w-256 border-0',
        //   header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        //   wrapper: 'min-h-0',
        // }}
        header={header}
        content={<div>Content</div>}
        leftSidebarHeader={<SidebarHeader title="Settings" icon={<SettingsOutlined />} />}
        leftSidebarContent={<SidebarContent routes={allRoutes} />}
        ref={pageLayout}
        innerScroll
      /> */}

      <Layout>{edit}</Layout>

      {/* // <ContactDialog /> */}
    </>
  );
};

export default SettingsEdit;
