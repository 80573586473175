import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  EditProps,
  Filter,
  required,
} from 'react-admin';

import { Post } from './types';
import UserReferenceInput from '../users/UserReferenceInput';

interface IPostListProps {}

const PostFilter = (props: IPostEditProps) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput label="User" source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const PostList = (props: IPostListProps) => {
  return (
    <List filters={<PostFilter />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="body" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

interface IPostEditProps extends EditProps {
  record?: Post;
}

const PostTitle = ({ record }: { record?: Post }) => {
  return <span>Post {record ? `"${record.title}"` : ''}</span>;
};

export const PostEdit = (props: IPostEditProps) => {
  return (
    <Edit title={<PostTitle />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        {/* <ReferenceInput source="userId" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput> */}
        <UserReferenceInput source="userId" reference="users" allowEmpty validate={required()} perPage={10000} />
        <TextInput source="id" />
        <TextInput source="title" />
        <TextInput multiline source="body" />
      </SimpleForm>
    </Edit>
  );
};

interface IPostCreateProps {}

export const PostCreate = (props: IPostCreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="userId" reference="users">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="title" />
        <TextInput multiline source="body" />
      </SimpleForm>
    </Create>
  );
};
