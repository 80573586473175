import React, { Fragment, useCallback, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Datagrid,
  TextField,
  ListProps,
  Identifier,
  useRecordContext,
  List,
  ListBase,
  ShowProps,
  ShowControllerProps,
  ArrayField,
  useListController,
  TopToolbar,
  Button,
} from 'react-admin';

import ContentAdd from '@material-ui/icons/Add';

import { Hotel } from '../types';

import ArrayList from '../../../containers/list/ArrayList';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

export interface RoomTypeListProps extends ListProps {
  selectedRow?: Identifier;
}

const RoomTypeList: FC<RoomTypeListProps> = (props: RoomTypeListProps) => {
  const { selectedRow } = props;
  return (
    <Datagrid>
      <TextField source="name" />
      <TextField source="numberOfPax" label="resources.hotelsedit.fields.numberOfPax" />
      <TextField source="numberOfExtrabed" label="resources.hotelsedit.fields.numberOfExtrabed" />
      <TextField source="numberOfChildExtrabed" label="resources.hotelsedit.fields.numberOfChildExtrabed" />
    </Datagrid>
  );
};

const TypeList = (props: ListProps) => {
  const controllerProps = useListController(props);
  return <div>sss</div>;
};

const ListActions = ({ className }: { className?: string }) => (
  <TopToolbar className={className}>
    <Button className={className} label="ra.action.create" variant="text">
      <ContentAdd />
    </Button>
  </TopToolbar>
);

const SanitizedRoomTypeList = (props: ShowProps & ShowControllerProps<Hotel>) => {
  const { loaded, ...context } = props;
  const { record } = context;
  debugger;
  return (
    <ArrayField {...context} addLabel={false} source="roomTypes">
      <ArrayList actions={<ListActions />}>
        <RoomTypeList />
      </ArrayList>
    </ArrayField>
  );
};

export default SanitizedRoomTypeList;
