import { ComponentType, ReactElement, ReactNode } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';

import { makeStyles } from '@material-ui/core/styles';
import MenuOpen from '@material-ui/icons/MenuOpen';
import DefaultIcon from '@material-ui/icons/ViewList';
// @ts-ignore
import classnames from 'classnames';

// @ts-ignore
import { motion } from 'framer-motion';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { useGetResourceLabel, getResources, ReduxState } from 'ra-core';
import { useTranslate, toggleSidebar } from 'react-admin';

import SidebarMenuButton from './SidebarMenuButton';

const accounts: any = {
  creapond: 'johndoe@creapond.com',
  withinpixels: 'johndoe@withinpixels.com',
};

const useStyles = makeStyles(
  theme => ({
    header: {
      marginLeft: theme.spacing(2),
      marginBottom: '0',
      fontSize: '2rem',
      display: 'flex',
      flexDirection: 'row',

      '& > svg': {
        fontSize: '26px',
        alignSelf: 'center',
        marginLeft: '-4px',
      },
    },
    title: {
      marginLeft: theme.spacing(2),
      alignSelf: 'center',
    },
    menubutton: {
      position: 'absolute',
      right: '0px',
      top: '10px',
      fontSize: '28px',
      backgroundColor: theme.palette.sidebar.main,
      '& svg': {
        fontSize: '26px',
      },
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
  }),
  { name: 'SidebarHeader' }
);

export interface SidebarHeaderProps {
  icon: ReactElement;
  title: string;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = props => {
  const [selectedAccount, setSelectedCount] = useState('creapond');
  const getResourceLabel = useGetResourceLabel();
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const { icon, title } = props;

  function handleAccountChange(ev: ChangeEvent<HTMLInputElement>) {
    setSelectedCount(ev.target.value);
  }

  return (
    <div className="flex flex-col h-full pt-12">
      <SidebarMenuButton ariaLabel="open left sidebar" />

      <div className={classes.header}>
        <SvgIcon>{icon ? icon : <DefaultIcon />}</SvgIcon>
        <Typography variant="h6" className={classes.title}>
          {open && (
            <>
              {translate(title, { _: title })}
              {/* {getResourceLabel(title, 2)} */}
            </>
          )}
        </Typography>
      </div>
    </div>

    // <div className="flex flex-col justify-center h-full p-24">
    //   <div className="flex items-center flex-1">
    //     <Icon
    //       component={motion.span}
    //       initial={{ scale: 0 }}
    //       animate={{ scale: 1, transition: { delay: 0.2 } }}
    //       className="text-24 md:text-32"
    //     >
    //       check_box
    //     </Icon>
    //     <Typography
    //       component={motion.span}
    //       initial={{ x: -20 }}
    //       animate={{ x: 0, transition: { delay: 0.2 } }}
    //       className="text-16 md:text-24 mx-12 font-semibold"
    //     >
    //       To-Do
    //     </Typography>
    //   </div>

    //   <motion.div initial={{ y: 20, opacity: 0.8 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}>
    //     <TextField
    //       id="account-selection"
    //       select
    //       label={selectedAccount}
    //       value={selectedAccount}
    //       onChange={handleAccountChange}
    //       placeholder="Select Account"
    //       margin="normal"
    //       variant="filled"
    //     >
    //       {Object.keys(accounts).map((key, value) => (
    //         <MenuItem key={key} value={key}>
    //           {accounts[key]}
    //         </MenuItem>
    //       ))}
    //     </TextField>
    //   </motion.div>
    // </div>
  );
};

export default SidebarHeader;
