//import { useSelector } from 'react-redux';
// import { useTheme, ThemeProvider } from '@material-ui/core/styles';
// import { selectContrastMainTheme } from 'app/store//settingsSlice';
//import {ClassesOverride} from 'react-admin'
export interface PageHeaderProps {
  header: any;
  classes: { header: any };
}

// classes?: ClassesOverride<typeof useStyles>;

function PageSimpleHeader(props: PageHeaderProps) {
  //const theme = useTheme();
  //const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));

  return <div className={props.classes.header}>{props.header && props.header}</div>;
}

export default PageSimpleHeader;
