import { lazy } from 'react';
import { linkToRecord, ResourceMatch } from 'react-admin';
import { Redirect } from 'react-router-dom';
import HotelEditIcon from '@material-ui/icons/HotelOutlined';
import InvCountIcon from '@material-ui/icons/HowToVoteOutlined';
import SubjectIcon from '@material-ui/icons/SubjectOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';

import { AppConfig, AppRoute } from '../../../types';
import HotelShow from './HotelShow';

const test = () => <div />;

const HotelEditConfig: AppConfig = {
  // HotelEdit: {
  // 	layout: {
  // 		config: {}
  // 	} //lazy(() => import('./HotelEditEdit')),
  // },
  key: 'HotelsApp',
  name: 'Hotely',
  icon: HotelEditIcon,
  routes: [
    {
      group: 'HotelEdit',
      key: 'HotelEdit/show',
      name: 'HotelEdit',
      path: '/hotels/:id/show',
      icon: HotelEditIcon,
      invisible: false,
      customRouteProps: {
        render: (props: any) => {
          console.log(props);
          debugger;
          const id = (props.match as ResourceMatch).params.id;

          const link = `${linkToRecord(`/hotels`, id)}/show`;

          return <Redirect to={link} />;
        },
      },
    },
    {
      group: 'HotelEdit',
      key: 'HotelEdit/invcount',
      name: 'invcount',
      path: '/hotels/:id/invcount',
      invisible: false,
      icon: InvCountIcon,
      customRouteProps: {
        component: test,
      },
    },
  ],
};

export default HotelEditConfig;
