import list from './ExportSystemList';
import edit from './ExportSystemEdit';
import create from './ExportSystemCreate';
import SyncOutlined from '@material-ui/icons/SyncOutlined';

export default {
  list,
  create,
  edit,
  icon: SyncOutlined,
};
