import * as React from 'react';
import { useCallback, FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { refreshView, useTranslate } from 'ra-core';
import { useRedirect } from 'react-admin';
import { AppState } from '../store/types';
import { drawerToggle } from '../containers/config/store/actions';

const SettingsIconButton: FC<SettingsIconButtonProps> = ({
  label = 'ra.action.settings',
  icon,
  onClick,
  className,
  ...rest
}) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isdrawerToggled = useSelector<AppState, boolean>(appState => appState.config.drawerToggled);
  const redirect = useRedirect();
  const handleClick = useCallback(
    event => {
      event.preventDefault();

      // dispatch(drawerToggle(!isdrawerToggled)); // TODO
      // if (typeof onClick === 'function') {
      //   onClick(event);
      // }

      redirect('/settings');
    },
    [dispatch, onClick, isdrawerToggled]
  );

  return (
    <Tooltip title={label && translate(label, { _: label })}>
      <IconButton
        aria-label={label && translate(label, { _: label })}
        className={className}
        color="inherit"
        onClick={handleClick}
        {...rest}
      >
        {isdrawerToggled ? <SettingsIcon /> : <SettingsOutlinedIcon />}
      </IconButton>
    </Tooltip>
  );
};

interface Props {
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
}

export type SettingsIconButtonProps = Props & IconButtonProps;

SettingsIconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};

export default SettingsIconButton;
