import {ExportSystem} from './types';

const exportSystems: Array<ExportSystem> = [
  {
    id: 1,
    name: 'Comarr',
    isActiveForSale: false,
    isActiveForSync: false,
    initializeFactoryType:''
   },
   {
    id: 2,
    name: 'Ares',
    isActiveForSale: true,
    isActiveForSync: true,
    initializeFactoryType:''
   },
     {
    id: 3,
    name: 'Previo',
    isActiveForSale: true,
    isActiveForSync: true,
    initializeFactoryType:''
   }
];

export default  exportSystems;
