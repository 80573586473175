import { ComponentType, ReactElement, ReactNode } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpen from '@material-ui/icons/MenuOpen';
// @ts-ignore
import classnames from 'classnames';

// @ts-ignore
import { motion } from 'framer-motion';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { useGetResourceLabel, getResources, ReduxState } from 'ra-core';
import { useTranslate, toggleSidebar } from 'react-admin';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'absolute',
      right: '5px',
      top: '10px',
      fontSize: '28px',
      backgroundColor: theme.palette.sidebar.main,
      color: 'white',
      '& svg': {
        fontSize: '26px',
      },
      '&:hover': {
        backgroundColor: theme.palette.sidebar.main,
      },
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
  }),
  { name: 'SidebarMenuButton' }
);

export interface SidebarMenuButtonProps {
  ariaLabel: string;
  className?: string;
}

const SidebarMenuButton: React.FC<SidebarMenuButtonProps> = props => {
  const getResourceLabel = useGetResourceLabel();
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const { ariaLabel, className } = props;
  return (
    <div>
      <Tooltip
        title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
          _: 'Open/Close menu',
        })}
        enterDelay={500}
      >
        <IconButton
          className={classnames(classes.root, className)}
          onClick={() => dispatch(toggleSidebar())}
          aria-label={ariaLabel}
        >
          <MenuOpen
            classes={{
              root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
            }}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default SidebarMenuButton;
