import * as React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

export interface IDashboardProps {}

export default function Dashboard(props: IDashboardProps) {
  return (
    <Card className="flex m-20 bg-blue-50">
      <CardHeader title="dash..." />
      <CardContent>slkjsdlkdjslkjdljdsl</CardContent>
    </Card>
  );
}
