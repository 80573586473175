import SettingsConfig from './settings/SettingsConfig'
import InvoicesConfig from './invoices/InvoicesConfig'
import HotelEditConfig from './hotels/edit/HotelEditConfig'
import HotelConfig from './hotels/HotelConfig'

import CustomersConfig from './customers/CustomersConfig'
import OrdersConfig from './orders/OrdersConfig'

 

import {AppConfig, AppRoute} from '../types'


const appsConfigs: AppConfig[] = [
	OrdersConfig,
	HotelConfig,
	SettingsConfig,
	InvoicesConfig,
	// HotelEditConfig,
	CustomersConfig
	
];

export default appsConfigs;