import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, memo, useRef } from 'react';
import PageSimpleHeader, { PageHeaderProps } from './PageSimpleHeader';
import PageSimpleSidebar, { PageSidebarRefProps, PageSidebarVariantType } from './PageSimpleSidebar';

const headerHeight = 60;
const toolbarHeight = 64;
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  '@global': {
    '#-main': {
      height: (props: PageProps) => props.innerScroll && '100vh',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    minHeight: '100%',
    position: 'relative',
    flex: '1 1 auto',
    height: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  innerScroll: {
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    zIndex: 2,
    maxWidth: '100%',
    minWidth: 0,
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  header: {
    height: headerHeight,
    minHeight: headerHeight,
    display: 'flex',
    // background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    borderBottom: '1px solid #dadada',
    marginBottom: '20px',
    // backgroundColor: theme.palette.primary.dark,
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    pointerEvents: 'none',
  },
  /* contentCardWrapper            : {
		 display : 'flex ',
		 flex    : '1 1 auto',
		 overflow: 'visible!important',
		 minWidth: 0,
		 '&.ps'  : {
			 overflow: 'visible!important'
		 }
	 },
	 contentCardWrapperInnerSidebar: {
		 display                     : 'block',
		 overflow                    : 'auto!important',
		 '-webkit-overflow-scrolling': 'touch',
		 '&.ps'                      : {
			 overflow: 'hidden!important'
		 },
		 '& $contentCard'            : {
			 borderRadius: 8
		 }
	 }, */
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 9999,
  },
  toolbar: {
    height: toolbarHeight,
    minHeight: toolbarHeight,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: '1 0 auto',
    padding: '0',
  },
  sidebarWrapper: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
      },
    },
  },
  sidebar: {
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        position: 'relative',
      },
    },
    width: drawerWidth,
    height: '100%',
  },
  leftSidebar: {
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderLeft: 0,
    },
  },
  rightSidebar: {
    [theme.breakpoints.up('lg')]: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRight: 0,
    },
  },
  sidebarHeader: {
    //height: headerHeight,
    //minHeight: headerHeight,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  sidebarHeaderInnerSidebar: {
    backgroundColor: 'transparent',
    color: 'inherit',
    height: 'auto',
    minHeight: 'auto',
  },
  sidebarContent: {},
  backdrop: {
    position: 'absolute',
  },
}));

export interface PageRefProps {
  //rootRef: React.Ref<HTMLDivElement>;
  toggleLeftSidebar: () => void;
  toggleRightSidebar: () => void;
}

const PageSimple = forwardRef<PageRefProps, PageProps>((props, ref) => {
  // console.info("render::PageSimple");
  const leftSidebarRef = useRef<PageSidebarRefProps>(null);
  const rightSidebarRef = useRef<PageSidebarRefProps>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(props);

  useImperativeHandle(ref, () => ({
    //rootRef,
    toggleLeftSidebar: () => {
      leftSidebarRef?.current?.toggleSidebar();
    },
    toggleRightSidebar: () => {
      rightSidebarRef?.current?.toggleSidebar();
    },
  }));

  return (
    <div className={clsx(classes.root, props.innerScroll && classes.innerScroll)} ref={rootRef}>
      <div className={clsx(classes.header, classes.topBg)} />

      <div className="flex flex-auto flex-col container z-10 h-full">
        {props.header && props.sidebarInner && <PageSimpleHeader header={props.header} classes={classes} />}

        <div className={classes.wrapper}>
          {(props.leftSidebarHeader || props.leftSidebarContent) && (
            <PageSimpleSidebar
              position="left"
              header={props.leftSidebarHeader}
              content={props.leftSidebarContent}
              variant={props.leftSidebarVariant || 'permanent'}
              innerScroll={props.innerScroll}
              sidebarInner={props.sidebarInner}
              classes={classes}
              ref={leftSidebarRef}
              rootRef={rootRef}
            />
          )}

          {/* <Scrollbars */}
          {/*    className={clsx(classes.contentCardWrapper, props.sidebarInner && classes.contentCardWrapperInnerSidebar)} */}
          {/*    enable={props.innerScroll && props.sidebarInner} */}
          {/* > */}
          <div className={classes.contentWrapper}>
            {/* <Scrollbars className={classes.contentWrapper} enable={props.innerScroll && !props.sidebarInner}> */}
            {props.header && !props.sidebarInner && <PageSimpleHeader header={props.header} classes={classes} />}

            {props.contentToolbar && <div className={classes.toolbar}>{props.contentToolbar}</div>}

            {props.content && <div className={classes.content}>{props.content}</div>}
            {/* </Scrollbars> */}
          </div>
          {/* </Scrollbars> */}

          {(props.rightSidebarHeader || props.rightSidebarContent) && (
            <PageSimpleSidebar
              position="right"
              header={props.rightSidebarHeader}
              content={props.rightSidebarContent}
              variant={props.rightSidebarVariant || 'permanent'}
              innerScroll={props.innerScroll}
              sidebarInner={props.sidebarInner}
              classes={classes}
              ref={rightSidebarRef}
              rootRef={rootRef}
            />
          )}
        </div>
      </div>
    </div>
  );
});

PageSimple.propTypes = {
  //   classes: PropTypes.exact({
  //     contentWrapper: PropTypes.string,
  //     content: PropTypes.string,
  //     leftSidebar: PropTypes.string,
  //     rightSidebar: PropTypes.string,
  //     header: PropTypes.string,
  //     wrapper: PropTypes.string,
  //   }),
  leftSidebarHeader: PropTypes.node,
  leftSidebarContent: PropTypes.node,
  leftSidebarVariant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
  rightSidebarHeader: PropTypes.node,
  rightSidebarContent: PropTypes.node,
  rightSidebarVariant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
  header: PropTypes.node,
  content: PropTypes.node,
  contentToolbar: PropTypes.node,
  sidebarInner: PropTypes.bool,
  innerScroll: PropTypes.bool,
};

//export type PageProps = PropTypes.InferProps<typeof propTypes>;

export interface PageProps {
  classes?: PropTypes.InferProps<{
    contentWrapper?: string;
    content?: string;
    leftSidebar?: string;
    rightSidebar?: string;
    header?: string;
    wrapper?: string;
  }>;
  leftSidebarHeader?: React.ReactNode;
  leftSidebarContent?: React.ReactNode;
  leftSidebarVariant?: PageSidebarVariantType;
  rightSidebarHeader?: React.ReactNode;
  rightSidebarContent?: React.ReactNode;
  rightSidebarVariant?: PageSidebarVariantType;
  header?: React.ReactNode;
  content: React.ReactNode;
  contentToolbar?: React.ReactNode;
  sidebarInner?: boolean;
  innerScroll?: boolean;
}

PageSimple.defaultProps = {};

export default memo(PageSimple);
