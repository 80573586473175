import * as React from 'react';
import { FC, memo, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { useRecordContext } from 'ra-core';

export interface CheckInOutTextFieldProps {
  variant: 'checkIn' | 'checkOut';
}

const CheckInOutTextField: FC<CheckInOutTextFieldProps> = memo<CheckInOutTextFieldProps>(props => {
  const { variant } = props;
  const record = useRecordContext(props);

  let from = get(record, `${variant}.from`);
  let to = get(record, `${variant}.to`);

  if (!!!from) {
    from = 'neomezeno';
  }
  if (!!!to) {
    to = 'neomezeno';
  }

  return (
    <>
      <Typography component="span" variant="body2">
        {from != null && typeof from !== 'string' ? JSON.stringify(from) : from || ''}
      </Typography>
      <Typography component="span" variant="body1">
        {' - '}
      </Typography>
      <Typography component="span" variant="body2">
        {to != null && typeof to !== 'string' ? JSON.stringify(to) : to || ''}
      </Typography>
    </>
  );
});

export default CheckInOutTextField;
