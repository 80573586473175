import React, { FC, MouseEvent } from 'react';
import {
  ReferenceManyField,
  ShowContextProvider,
  useShowContext,
  useShowController,
  Pagination as RaPagination,
  TextField,
  UrlField,
  SimpleShowLayout,
  Toolbar,
  useTranslate,
  ShowProps,
  ToolbarProps,
  useRecordContext,
  useGetOne,
  ReferenceField,
  BooleanInput,
  Translate,
  Edit,
  SimpleForm,
} from 'react-admin';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

import HotelDetailEditDialog from './HotelDetailEditDialog';
import HotelActivityInput from './HotelActivityInput';

import { Hotel } from '../types';
import { AnyMxRecord } from 'dns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
    },
    inline: {},
    media: {
      height: 0,
      paddingTop: '30.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    isActive: {
      backgroundColor: theme.palette.background.paper,
      background: 'linear-gradient(135deg, #3ff436, transparent) no-repeat bottom;',
      backgroundSize: '1% 100%',
      backgroundPosition: 'initial',
    },
    isDeactive: {
      backgroundColor: theme.palette.background.paper,
      background: 'linear-gradient(135deg, #f44336, transparent) no-repeat bottom;',
      backgroundSize: '1% 100%',
      backgroundPosition: 'initial',
    },

    avatar: {
      backgroundColor: 'lightgray',
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);

const HotelName = ({ record, translate }: { record?: Hotel; translate: Translate }) => {
  return (
    <Box flexDirection="row">
      <TextField source="name" variant="h6" />
      <Typography component="span" variant="h6">
        {' '}
      </Typography>
      <TextField source="category" variant="h6" />
    </Box>
  );
};

const AddressDetail = ({ record, translate }: { record?: Hotel; translate: Translate }) => {
  return (
    <>
      <Box>
        <ReferenceField source="ownerId" reference="owners">
          <TextField source="name" />
        </ReferenceField>
      </Box>
      <Box flexDirection="row">
        <TextField source="address.street" label={translate('resources.hotels.fields.address.street')} />
        <Typography variant="body1" component="span">
          {', '}
        </Typography>
        <TextField source="address.city" label={translate('resources.hotels.fields.address.city')} />
      </Box>
    </>
  );
};

const ActiveFormBody = ({ handleSubmit }: any) => {
  // enable the warn when unsaved changes feature
  //useWarnWhenUnsavedChanges(true);
  return (
    <form onSubmit={handleSubmit}>
      <BooleanInput source="isActive" label="Aktivní" />
      {/* <label id="firstname-label">First Name</label>
      <Field name="firstName" aria-labelledby="firstname-label" component="input" />
      <button type="submit">Submit</button> */}
    </form>
  );
};

const HotelDetail: FC<ShowProps> = props => {
  const { loading, ...context } = useShowContext<Hotel>(props);
  const { record } = context;
  const classes = useStyles();
  const translate = useTranslate();

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [showDialog, setShowDialog] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const options: any = {
    edit: {
      enabled: true,
      needData: false,
      icon: <EditIcon fontSize="small" />,
      label: translate('ra.action.edit'),
      action: (data: any) => {
        setShowDialog(true);
      },
    },
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleOnClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleItemClick = (e: MouseEvent<HTMLLIElement | HTMLButtonElement>) => {
    setAnchorEl(null);
    const key = e.currentTarget.getAttribute('value') as string;
    if (options[key].needData) {
      //   dataProvider
      //     .getList('song', songQueryParams)
      //     .then(response => {
      //       let { data, ids } = extractSongsData(response);
      //       options[key].action(data, ids);
      //     })
      //     .catch(() => {
      //       notify('ra.page.error', 'warning');
      //     });
    } else {
      options[key].action();
    }

    e.stopPropagation();
  };

  const open = Boolean(anchorEl);

  const activeChangedHandler = () => {};

  return (
    <>
      <Card
        className={clsx(classes.isActive, {
          [classes.isDeactive]: !record?.isActive,
        })}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src="https://www.bezvapobyt.cz/Imagegen.ashx?width=220&image=/media/13696/10000.jpg"
            ></Avatar>
          }
          action={
            <Box flexDirection="row">
              <IconButton aria-label="edit" value={'edit'} onClick={handleItemClick}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="more" aria-controls="context-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="context-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleOnClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {Object.keys(options).map(
                  key =>
                    options[key].enabled && (
                      <MenuItem value={key} key={key} onClick={handleItemClick}>
                        <ListItemIcon>{options[key].icon}</ListItemIcon>
                        <ListItemText primary={options[key].label} />
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>
          }
          title={<HotelName record={record} translate={translate} />}
          subheader={<AddressDetail record={record} translate={translate} />}
        />
        {/* <CardMedia className={classes.media} image="/static/images/cards/paella.jpg" title="Paella dish" /> */}
        <CardContent>
          <UrlField source="slug" addLabel={true} label={translate('resources.hotels.fields.address.street')} />
          {/* <Form onSubmit={activeChangedHandler} component={ActiveFormBody}></Form> */}
          {/* <HotelActivityInput {...props} /> */}
          <Typography variant="body2" color="textSecondary" component="p"></Typography>
        </CardContent>
        <CardActions disableSpacing>
          {/* <Button size="small" color="primary">
            {translate('ra.action.edit')}
          </Button> */}
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Method:</Typography>
            <Typography paragraph>
              Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10 minutes.
            </Typography>
            <Typography paragraph>
              Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken,
              shrimp and chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp
              to a large plate and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic,
              tomatoes, onion, salt and pepper, and cook, stirring often until thickened and fragrant, about 10 minutes.
              Add saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
            </Typography>
            <Typography paragraph>
              Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook without stirring,
              until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
              mussels, tucking them down into the rice, and cook again without stirring, until mussels have opened and
              rice is just tender, 5 to 7 minutes more. (Discard any mussels that don’t open.)
            </Typography>
            <Typography>Set aside off of the heat to let rest for 10 minutes, and then serve.</Typography>
          </CardContent>
        </Collapse> */}
      </Card>
      {/* // record
        // 
        //   <TextField source="name" fullWidth />
        //   <ReferenceField source="ownerId" reference="owners">
        //     <TextField source="name" />
        //   </ReferenceField>
        //   <TextField source="slug" fullWidth style={{ paddingRight: '0!important' }} />
        //   <CustomDivider />
        //   <TextField source="category" formClassName={classes.inline} />
        //   <TextField source="destination" formClassName={classes.inline} />
        //   <CustomDivider />
        //   <Box flexDirection="row">
        //     <TextField source="address.street" label={translate('resources.hotels.fields.address.street')} fullWidth />
        //     <TextField
        //       source="address.city"
        //       label={translate('resources.hotels.fields.address.city')}
        //       formClassName="ddddd"
        //       className="dddd"
        //     />
        //     <TextField
        //       source="address.postalCode"
        //       label={translate('resources.hotels.fields.address.postalCode')}
        //       formClassName={classes.inline}
        //     />
        //   </Box>
        //   <Box flexDirection="row">
        //     <TextField source="address.street" label={translate('resources.hotels.fields.address.street')} fullWidth />
        //     <TextField
        //       source="address.city"
        //       label={translate('resources.hotels.fields.address.city')}
        //       formClassName="ddddd"
        //       className="dddd"
        //     />
        //     <TextField
        //       source="address.postalCode"
        //       label={translate('resources.hotels.fields.address.postalCode')}
        //       formClassName={classes.inline}
        //     />
        //   </Box>
        //   <CustomDivider />
        // </>
      //)} */}

      <HotelDetailEditDialog
        {...props}
        onChange={() => setShowDialog(false)}
        onClose={() => setShowDialog(false)}
        open={showDialog}
        className=""
      />
    </>
  );
};

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

export default HotelDetail;
