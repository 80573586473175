import React, { MouseEventHandler, FC } from 'react';
import {
  useShowController,
  ShowContextProvider,
  ShowProps,
  useTranslate,
  useRecordContext,
  useGetOne,
  useShowContext,
  TopToolbar,
  Button,
  DeleteWithConfirmButton,
  ShowControllerProps,
  LinearProgress,
} from 'react-admin';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import Layout from './Layout';
import HotelDetail from './HotelDetail';
import HotelAddress from './HotelAddress';

import HotelCheckInOut from './HotelCheckInOut';
import ChangeActivityWithConfirmButton from './ChangeActivityWithConfirmButton';
import ReportMenuButton from './ReportMenuButton';
import NextMenuButton from './NextMenuButton';
import RoomTypeList from './RoomTypeList';
import { Hotel } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const BulkActionButtons = (props: any) => (
  <Box display="flex" flexDirection="row">
    <ChangeActivityWithConfirmButton variant="outlined" />
    <Box width={2} />
    <ReportMenuButton />
    <Box width={2} />
    <NextMenuButton />
  </Box>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const HotelShowLayout: FC<ShowProps & ShowControllerProps<Hotel>> = props => {
  // const translate = useTranslate();

  const { loading, record } = props;

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: any) {
    return {
      id: `hotel-tab-${index}`,
      'aria-controls': `hotel-tabpanel-${index}`,
    };
  }

  return (
    <Layout>
      <Container maxWidth="xl">
        {record ? (
          <>
            <Box padding="10px 0" className={classes.root}>
              <BulkActionButtons {...props} />
            </Box>
            <Grid container direction="row" spacing={2} className={classes.root}>
              <Grid container item direction="column" md={4} spacing={2} className={classes.root}>
                <Grid item>
                  <HotelDetail {...props} />
                </Grid>
                <Grid item>
                  <HotelAddress {...props} />
                </Grid>
                <Grid item>
                  <HotelCheckInOut {...props} variant="checkIn" />
                </Grid>
                <Grid item>
                  <HotelCheckInOut {...props} variant="checkOut" />
                </Grid>
              </Grid>

              <Grid container item direction="column" spacing={2} md={8} className={classes.root}>
                <div>
                  <CardContent>
                    <Tabs value={value} onChange={handleChange} aria-label="hotel tabs">
                      <Tab label="Typy pokojů" {...a11yProps(0)} />
                      <Tab label="CMS" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <RoomTypeList {...props} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div>CMS</div>
                    </TabPanel>
                  </CardContent>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <LinearProgress variant="indeterminate" />
        )}
      </Container>
    </Layout>
  );
};

const HotelShow: FC<ShowProps> = (props: ShowProps) => {
  const controllerProps = useShowController<Hotel>(props);
  return (
    <ShowContextProvider value={controllerProps}>
      <HotelShowLayout {...props} {...controllerProps} />
    </ShowContextProvider>
  );
};

export default HotelShow;
