import React, { Fragment, useCallback, FC } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { push } from 'react-router-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Datagrid,
  List,
  TextField,
  CardActions,
  CreateButton,
  EditButton,
  ListProps,
  useRedirect,
  TopToolbar,
} from 'react-admin';
import { Route, RouteComponentProps } from 'react-router';
import { Drawer } from '@material-ui/core';
import TagCreate from './TagCreate';
import TagEdit from './TagEdit';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const TagListActions = ({ basePath, className }: { basePath?: string; className?: string }) => (
  <TopToolbar className={className}>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

// interface TagListProps extends ListProps {
// }

type RouteParams = { id: string };

const TagList: FC<ListProps> = (props: ListProps) => {
  const redirectTo = useRedirect();

  const handleClose = useCallback(() => {
    redirectTo('/albums');
  }, []);

  const classes = useStyles();

  return (
    <Fragment>
      <List {...props} sort={{ field: 'title', order: 'ASC' }} actions={<TagListActions />}>
        <Datagrid>
          <TextField source="title" />
          <EditButton />
        </Datagrid>
      </List>
      <Route path="/albums/create">
        {({ match }: RouteComponentProps<RouteParams>) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <TagCreate className={classes.drawerContent} onCancel={handleClose} {...props} />
          </Drawer>
        )}
      </Route>
      <Route path="/albums/:id">
        {({ match }: RouteComponentProps<RouteParams>) => {
          const isMatch = match && match.params && match.params.id !== 'create';

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {isMatch ? (
                <TagEdit
                  className={classes.drawerContent}
                  id={isMatch ? match.params.id : '0'}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default TagList;

// export default compose(connect(undefined, { push }), withStyles(styles))(TagList);
