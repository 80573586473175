import * as React from 'react';
import { useCallback, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/styles/makeStyles';
import { fetchRelatedRecords, useDataProvider, useNotify, Identifier, Exporter, useListContext } from 'ra-core';

import { Button, ButtonProps, useTranslate } from 'react-admin';
import { StyledMenu, StyledMenuItem } from '../menu/StyledButtonMenu';
/**
 * Export the selected rows
 *
 * To be used inside the <List bulkActionButtons> prop.
 *
 * @example // basic usage
 * import * as React from 'react';
 * import { Fragment } from 'react';
 * import { BulkDeleteButton, BulkChangeDataButton } from 'react-admin';
 *
 * const PostBulkActionButtons = ({ basePath }) => (
 *     <Fragment>
 *         <BulkChangeDataButton />
 *         <BulkDeleteButton basePath={basePath} />
 *     </Fragment>
 * );
 *
 * export const PostList = (props) => (
 *     <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
 *         ...
 *     </List>
 * );
 */

const useStyles = makeStyles({
  root: {
    marginRight: '10px',
    minHeight: 30,
  },
});

const BulkChangeDataButton: FunctionComponent<BulkChangeDataButtonProps> = props => {
  const { onClick, onChange, label = '', title, icon, exporter: customExporter, menu, ...rest } = props;
  const { exporter: exporterFromContext, resource, selectedIds } = useListContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  //   const handleClick = useCallback(
  //     event => {
  //       //   exporter &&
  //       //     dataProvider
  //       //       .getMany(resource, { ids: selectedIds })
  //       //       .then(({ data }) => exporter(data, fetchRelatedRecords(dataProvider), dataProvider, resource))
  //       //       .catch(error => {
  //       //         console.error(error);
  //       //         notify('ra.notification.http_error', 'warning');
  //       //       });
  //       if (typeof onClick === 'function') {
  //         onClick(event);
  //       }
  //     },
  //     [dataProvider, exporter, notify, onClick, resource, selectedIds]
  //   );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    if (typeof onClick === 'function') {
      onClick(Boolean(anchorEl));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLLIElement>, data: MenuItemProps) => {
    if (typeof onChange === 'function') {
      onChange(data);
    }

    if (typeof data.onClick === 'function') {
      data.onClick(data);
    }

    handleClose();
  };

  const translatedLabel = React.useMemo(
    () => label && translate(label, { smart_count: selectedIds?.length }),
    [label, selectedIds]
  );
  const translatedTitle = React.useMemo(
    () => (label || title ? translate(title ? title : label, { smart_count: selectedIds?.length }) : ''),
    [label, title, selectedIds]
  );

  return (
    <div>
      <Tooltip title={translatedTitle} arrow>
        <Button
          variant={props.variant ? props.variant : 'outlined'}
          color={props.color ? props.color : 'primary'}
          className={classes.root}
          endIcon={Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleClick}
          label={translatedLabel}
          {...sanitizeRestProps(rest)}
        >
          {icon && icon}
        </Button>
      </Tooltip>
      {menu && (
        <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {menu.map(field => (
            <StyledMenuItem data-sort={field} key={field.id} onClick={e => handleMenuClick(e, field)} selected={false}>
              {field.icon && <ListItemIcon>{field.icon}</ListItemIcon>}
              <ListItemText
                primary={
                  field.label && typeof field.label === 'string'
                    ? translate(field.label, { smart_count: selectedIds?.length })
                    : field.label
                }
              />
            </StyledMenuItem>
          ))}

          {/* <StyledMenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sent mail" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <DraftsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Drafts" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <InboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </StyledMenuItem> */}
        </StyledMenu>
      )}
    </div>
  );
};

//const defaultIcon = <ExpandMoreIcon />;

const sanitizeRestProps = ({
  basePath,
  filterValues,
  selectedIds,
  resource,
  ...rest
}: Omit<BulkChangeDataButtonProps, 'exporter' | 'label' | 'menu' | 'title' | 'onChange'>) => rest;

interface Props {
  basePath?: string;
  exporter?: Exporter;
  filterValues?: any;
  icon?: JSX.Element;
  label?: string;
  title?: string;
  onClick?: (isOpened: boolean) => void;
  onChange?: (data: MenuItemProps) => void;
  selectedIds?: Identifier[];
  resource?: string;
  menu?: MenuItemProps[];
}

export interface MenuItemProps {
  id: string;
  label: React.ReactElement | string;
  icon?: React.ReactElement;
  onClick?: (data: MenuItemProps) => void;
}

export type BulkChangeDataButtonProps = Props & Omit<ButtonProps, 'onChange'>;

BulkChangeDataButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  label: PropTypes.string,
  resource: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  icon: PropTypes.element,
  //   menu: PropTypes.arrayOf(
  //     PropTypes.exact({
  //       id: PropTypes.string.isRequired,
  //       label: PropTypes.string.isRequired,
  //       icon: PropTypes.element,
  //       onClick: PropTypes.func,
  //     })
  //   ),
};

export default BulkChangeDataButton;
