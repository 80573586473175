import React from 'react';
import { makeStyles, fade, withStyles, createStyles } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingIndicator, useTranslate, toggleSidebar, ReduxState } from 'react-admin';

import { Theme, TabProps, TabsProps } from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// @ts-ignore
import classNames from 'classnames';

import SettingsApplicationsOutlined from '@material-ui/icons/SettingsApplicationsOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import MenuIcon from '@material-ui/icons/Menu';

import UserMenu from '../UserMenu';
import SettingsIconButton from '../SettingsIconButton';
import NotificationIconButton from '../../containers/notification/NotificationIconButton';
import Logo from './Logo';
interface StyledTabsProps extends Omit<TabsProps, 'value' | 'onChange'> {
  value: string;
  //onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.palette.secondary.contrastText,
      },
    },
  })
)((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps extends Omit<TabProps, 'label'> {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#fff',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: 100,
      '&:focus': {
        opacity: 1,
      },
      '&:hover': {
        opacity: 1,
      },
    },
  })
)((props: any) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  toolbar: {
    paddingLeft: 0,
  },
  menuButton: {
    marginLeft: '0.2em',
    marginRight: '0.2em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  logo: {
    width: '160px',
    margin: '-1px -15px 0 0px',
    height: 'auto',
  },
  indicator: {
    backgroundColor: theme.palette.secondary.contrastText,
    maxWidth: 40,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),

    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height: '34px',
    margin: '7px 24px 5px 24px',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      marginLeft: '42px',
      width: '60%',
      display: 'flex',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('lg')]: {
    //   width: '80ch',
    // },
    // [theme.breakpoints.up('md')]: {
    //   width: '40ch',
    // },
  },
  sectionDesktop: {
    '& svg': {
      fontSize: '1.4rem',
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const open = useSelector<ReduxState>(app => app.admin.ui.sidebarOpen);

  return (
    <nav className={classes.root}>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <Box flex={1} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Tooltip
                title={translate(open ? 'ra.action.close_menu' : 'ra.action.open_menu', {
                  _: 'Open/Close menu',
                })}
                enterDelay={500}
              >
                <IconButton
                  color="inherit"
                  onClick={() => dispatch(toggleSidebar())}
                  className={classNames(classes.menuButton)}
                >
                  <MenuIcon
                    classes={{
                      root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
                    }}
                  />
                </IconButton>
              </Tooltip>

              {/* <img
                className={classes.logo}
                src={'https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg'}
                alt="HotelPoint.cz"
              /> */}
              <Logo className={classes.logo} alt="HotelPoint.cz" />

              {/* <Typography component="span" variant="h6" className={classes.title}>
                HotelPoint.cz
              </Typography> */}
            </Box>

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={translate('ra.action.search')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            {/* <div className={classes.grow} /> */}

            <Box display="flex" className={classes.sectionDesktop}>
              <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <MailIcon />
                </Badge>
              </IconButton>
              {/* <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <NotificationIconButton />
              <SettingsIconButton />
              <LoadingIndicator />
              <UserMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Header;
