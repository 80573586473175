import { CHANGE_APP, ChangeAppAction } from "./types";
import {AppConfig} from '../../types'

export function changeApp(currentApp: AppConfig): ChangeAppAction {
  return {
    type: CHANGE_APP,
    payload: currentApp
  };
}

