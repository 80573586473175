import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  email,
  Button,
  SaveButton,
  TextInput,
  DateInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FormWithRedirectRenderProps,
  FormWithRedirectSave,
  Record,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

interface Props {
  onChange: () => void;
  className: string;
}

function UserQuickCreateButton({ onChange, className }: Props) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }: { data: Partial<Record> }) => {
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change('userId', data.id);
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create" className={className}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create new user">
        <DialogTitle>Create new user</DialogTitle>

        <FormWithRedirect
          resource="users"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving, valid }: FormWithRedirectRenderProps) => (
            <>
              <DialogContent>
                <TextInput source="name" validate={required()} fullWidth />
                <TextInput source="username" validate={required()} fullWidth />
                <TextInput source="email" type="email" validate={[required(), email()]} fullWidth />
                <TextInput source="address.street" validate={required()} fullWidth />
                <DateInput source="created_at" />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  disabled={pristine || loading || !valid}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default UserQuickCreateButton;
