import * as React from 'react';
import { FC, ReactNode, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import lodashGet from 'lodash/get';
import { useRouteMatch } from 'react-router-dom';
// @ts-ignore
import { useMediaQuery, Theme } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import MenuOpen from '@material-ui/icons/MenuOpen';
// @ts-ignore
import classnames from 'classnames';
import { useGetResourceLabel, getResources, ReduxState } from 'ra-core';

import { useTranslate } from 'react-admin';
import { useAppContext } from '../../AppContext';
import { AppConfig } from '../../types';
import { AppState } from '../../store/types';
import { changeApp } from '../../store/actions';

import MenuItemLink from './AppMenuItemLink';
import DashboardMenuItem from './DashboardMenuItem';
import SidebarMenu from '../../components/sidebar/SidebarMenu';
import SidebarContainer from '../../components/sidebar/SidebarContainer';
export const MENU_WIDTH = '5.0rem';
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0',
      padding: '0',
      backgroundColor: theme.palette.sidebar.main,
      minHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '0',
      },

      '&::before': {
        background: 'linear-gradient(to right,transparent,#aaa)',
        content: '""',
        height: '100%',
        opacity: '.2',
        pointerEvents: 'none',
        position: 'absolute',
        right: '0',
        width: '.8rem',
        zIndex: '2',
      },
    },
    header: {
      marginLeft: theme.spacing(2),
      marginBottom: '0.5em',
      fontSize: '2rem',
      display: 'flex',
      flexDirection: 'row',

      '& > svg': {
        fontSize: '26px',
        alignSelf: 'center',
        marginLeft: '-4px',
      },
    },
    title: {
      marginLeft: theme.spacing(2),
      alignSelf: 'center',
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      //width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      '& .list-item-icon': {
        minWidth: 'auto',
        display: 'block',
      },
    },
    menubutton: {
      position: 'absolute',
      right: '0px',
      top: '10px',
      fontSize: '28px',
      '& svg': {
        fontSize: '26px',
      },
    },
    sidebar: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }),
  { name: 'HPAppMenu' }
);

const AppMenu: FC<AppMenuProps> = props => {
  const { classes: classesOverride, className, dense, hasDashboard, onMenuClick, ...rest } = props;

  const [currentAppKey, setCurrentAppKey] = useState('');

  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const currentApp = useSelector((state: AppState) => state.apps.currentApp);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const getResourceLabel = useGetResourceLabel();
  const appContext = useAppContext();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { appsConfigs } = appContext;

  //const onMenuClickHandler = useCallback(()=>);

  // console.log(routes);
  // const match = useRouteMatch(routes.map(route => route.path));
  // const currentPath = match?.path ?? '/';

  //const findedRoutes = routes.filter(p => p.group === 'home');
  //const currentApp = appsConfigs.find(p => p.key === currentAppKey);

  return (
    <Box display="flex" flexDirection="row">
      <List
        className={classnames(
          classes.main,
          {
            [classes.open]: open,
            [classes.closed]: !open,
          },
          className
        )}
        {...rest}
      >
        {hasDashboard && (
          <DashboardMenuItem onClick={() => dispatch(changeApp({} as AppConfig))} dense={dense} sidebarIsOpen={open} />
        )}
        {appsConfigs.map(resource => (
          <MenuItemLink
            key={resource.key}
            type="button"
            to=""
            isActive={() => (currentApp ? currentApp.key === resource.key : false)}
            // to={{
            //   pathname: `${resource.path}`,
            //   state: { _scrollToTop: true },
            // }}
            primaryText={translate(resource.name, { _: resource.name })}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={(e: any) => dispatch(changeApp(resource))}
            dense={dense}
          />
        ))}
      </List>
      {/* {isXSmall && logout} */}

      <div id="app-menu"></div>

      {currentApp && currentApp.appMenu ? (
        <SidebarContainer popup className={classes.sidebar}>
          {currentApp.appMenu}
        </SidebarContainer>
      ) : null}
      {/* {findedRoutes.map(resource => (
        <SidebarContainer>sdsdds</SidebarContainer>
      ))} */}
      {/* <SidebarMenu routes={routes} /> */}
    </Box>
  );
};

export interface AppMenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  onMenuClick?: () => void;
}

AppMenu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

AppMenu.defaultProps = {
  onMenuClick: () => null,
};

export default AppMenu;
