import React, { useState, useCallback, FC, ReactChildren, ReactChild } from 'react';
import { useFormState } from 'react-final-form';
import { ReferenceInput, SelectInput, ReferenceInputProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import UserQuickCreateButton from './UserQuickCreateButton';
// import UserQuickPreviewButton from './UserQuickPreviewButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '12px 10px 12px 10px',
    margin: '0 0px 20px 5px',
  },
});

const spySubscription = { values: true };

interface UserReferenceInputProps extends Omit<ReferenceInputProps, 'children'> {
  children?: ReactChild | ReactChildren;
}

const UserReferenceInput: FC<UserReferenceInputProps> = (props: UserReferenceInputProps) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const { values } = useFormState({ subscription: spySubscription });
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div className={classes.root}>
      {/* <ReferenceInput key={version} {...props}>
        <SelectInput optionText="title" />
      </ReferenceInput> */}

      <ReferenceInput key={version} {...props} source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <UserQuickCreateButton onChange={handleChange} className={classes.button} />
      {/* {!!values.User_id && <UserQuickPreviewButton id={values.User_id} />} */}
    </div>
  );
};

export default UserReferenceInput;
