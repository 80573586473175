import React, { Fragment, useCallback, FC } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { push } from 'react-router-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import {
  Datagrid,
  List,
  TextField,
  CardActions,
  CreateButton,
  EditButton,
  ShowButton,
  ListProps,
  TopToolbar,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BulkExportButton,
  BulkActionsToolbarProps,
  ReferenceField,
  Identifier,
} from 'react-admin';

import HotelCreate from './HotelCreate';
import HotelEdit from './HotelEdit';
import HotelListAside from './HotelListAside';
import Layout from './Layout';
import BulkChangeActiviteButton from './components/BulkChangeActiviteButton';
import BulkChangeRatingButton from './components/BulkChangeRatingButton';
import BoxContainer from '../../components/layout/BoxContainer';
import rowStyle from './rowStyle';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const HotelFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      {/* <ReferenceInput label="User" source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput> */}

      <SelectInput label="Slug" source="slug" optionText="slug" />
    </Filter>
  );
};

// const HotelListActions = ({ basePath, className }: { basePath?: string; className?: string }) => (
//   <TopToolbar className={className}>
//     <CreateButton basePath={basePath} />
//   </TopToolbar> actions={<HotelListActions />} filters={<HotelFilter />}
// );

const BulkActionButtons = (props: any) => (
  <Fragment>
    <BulkChangeActiviteButton id="btn-1" {...props} />
    <BulkChangeRatingButton id="btn-2" {...props} />

    <BulkExportButton label="Export" {...props} />
    {/* default bulk delete action */}
    {/* <BulkDeleteButton {...props} /> */}
  </Fragment>
);

export interface HotelListProps extends ListProps {
  selectedRow?: Identifier;
}

const HotelList: FC<HotelListProps> = (props: HotelListProps) => {
  const { selectedRow } = props;

  return (
    <Layout>
      <Container maxWidth="xl">
        <BoxContainer>
          <List
            {...props}
            sort={{ field: 'name', order: 'ASC' }}
            //aside={<HotelListAside />}
            filters={<HotelFilter />}
            bulkActionButtons={<BulkActionButtons />}
          >
            <Datagrid
              rowClick="show"
              // @ts-ignore
              rowStyle={rowStyle(selectedRow)}
            >
              <TextField source="name" />
              <ReferenceField
                // record={contact}
                source="ownerId"
                reference="owners"
                basePath="/owners"
                link={true}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="slug" />
              <TextField source="category" />
              <TextField source="destination" />
              <ShowButton />
            </Datagrid>
          </List>
        </BoxContainer>
      </Container>
    </Layout>
  );
};

export default HotelList;
