import list from './HotelList';
import edit from './HotelEdit';
import show from './edit/HotelShow';
import create from './HotelCreate';
import HotelOutlined from '@material-ui/icons/HotelOutlined';

export default {
  list,
  create,
  edit,
  show,
  icon: HotelOutlined,
};
