import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";

// // zelena background-color: #16724b;
// //sediva background - color: #463b37;

/*background-color: #474a78; appbar*/
/* background-color: #33344f; sidebar*/
/* background-color: #6368ae; sidebar-active */
/* 5b4680 - docela pekny sidebar*/

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "#6264a7",
      contrastText: '#fff',
    },
    secondary: {
      main: "#33344f",
      contrastText: '#fff',
    },
    sidebar: {
      main: "#ebebeb",
      light: '#f5f6fa',
      dark: '#d0cdcd',
      contrastText: '#616161'
    },
    background: {
      default: '#F5F6FA'
    }
  })
);

const typography = {
  fontFamilySecondary: '"Segoe UI", SFProDisplay, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "system-ui", sans-serif',
  fontFamily: '"Segoe UI", SFProDisplay, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "system-ui", sans-serif',
  fontSize: 12,
  fontStyle: "normal",
  fontWeightLight: 400,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  color: palette.text.primary,
};

const typographyBase = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontStyle: typography.fontStyle,
  color: typography.color,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary,
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily,
};



const defaultMuiTheme = createMuiTheme();
// primary #003f69
//sidebar 10234d  color:white
const newTheme = {
  palette,
  sidebar: {
    width: 'auto',
    closedWidth: 'auto',
  },
  typography: {
    ...typographyBase,
  },
  shape: {
    borderRadius: '2px',
  },
  //shadows: ["none"],
  // shadows: Array(25).fill("none") as Shadows,
  overrides: {
    MuiAppBar: {
      root: {
        // background: `linear-gradient(297deg, #231f30, #003f69);`,

      },
    },

    RaSidebar: {
      root: {
        color: palette.sidebar.contrastText,
      },
      width: 80,
      drawerPaper: {
        backgroundColor: palette.sidebar.main,
        color: palette.sidebar.contrastText,
        height: 'calc(100vh - 50px)',
        "& svg": {
          color: "white",
          fontSize: '1.2rem'
        },
        //(min-width: 0px) and (max-width: 599.95px)
        borderRight: 'none',
        [defaultMuiTheme.breakpoints.only('xs')]: {
          backgroundColor: palette.sidebar.main,
        },
      },
    },

    RaMenuItemLink: {
      root: {
        color: 'inherit', //palette.sidebar.secondary,
        opacity: '0.7',
        fontSize: '0.95rem',
        '&:hover': {
          opacity: '1',
          color: 'inherit',
          //color: palette.sidebar.secondary,
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRightWidth: defaultMuiTheme.spacing(0.5),
          borderRightStyle: "solid",
        },
      },
      active: {
        opacity: '1',
        borderLeftStyle: "none",
        borderRightColor: palette.sidebar.secondary,
        borderRightWidth: defaultMuiTheme.spacing(0.5),
        borderRightStyle: "solid",
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",//palette.action.selected,
        transition: "border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s",
        color: 'inherit'
        //color: palette.sidebar.secondary,
      },

    },
    RaDatagridHeaderCell: {
      backgroundColor: palette.background.default
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: palette.background.default,
        borderTop: '1px solid #dcdcdc',
        borderBottom: '1px solid #dcdcdc'
      }
    },
    RaList: {
      content: {
        boxShadow: 'none',
      }
    },


    // RaMenuItemLink: {
    //   root: {
    //     color: '#fff',
    //     fontSize: '0.9rem'
    //   },
    //   active: {
    //     color: '#fff',
    //   },
    //   MuiSvgIcon: {
    //     root: {
    //       fill: palette.sidebar.contrastText
    //     }
    //   },
    // },


    // RaMenu: {
    //   main: {
    //     color: '#fff',
    //     fontSize: '0.9rem'
    //   },
    //   RaMenuItemLink: {
    //     root: {
    //       color: '#fff',
    //       fontSize: '0.9rem'
    //     }
    //   }
    // },
    MuiTextField: {
      backgroundColor: "#f0f8ff70"
    },

    MuiTableCellHead: {
      backgroundColor: "red",
    },

    MuiTableCell: {
      head: {
        backgroundColor: "red",
      }
    },

    RaDatagridHeaderCell: {
      backgroundColor: "red",
      root: {
        backgroundColor: "red"
      },
      head: {
        backgroundColor: "red",
      }
    },
  },
  props: {
    MuiInput: {
      variant: "outlined",
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiInputBase: {
      variant: 'outlined',
    },
    RaSimpleForm: {
      variant: 'outlined',
    },

    RaDatagridHeaderCell: {
      backgroundColor: "red"
    },
  }
};

/*
--gradientBlueRed: linear-gradient(45deg,#140757,#ed1c24);
    --gradientBlueRedDark: linear-gradient(21.98deg,#120853 14.38%,#6f1c3f 85.57%);
    --gradientGold: linear-gradient(45deg,#cfb572,#a58b4b);
    --gradientGoldHover: linear-gradient(45deg,#c2a14c,#85703d);
    --gradientGoldActive: linear-gradient(45deg,#b7953e,#62532d);
    --fontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;

*/

const rawTheme = {
  palette,

  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      textTransform: "uppercase",
      fontSize: "3rem",
    },
    h2: {
      ...typographyHeader,
      textTransform: "uppercase",
      fontSize: "2.75rem",
    },
    h3: {
      ...typographyHeader,
      fontSize: "2.5rem",
    },
    h4: {
      ...typographyHeader,
      fontSize: "2rem",
    },
    h5: {
      ...typographyHeader,
      fontWeight: typography.fontWeightMedium,
      fontSize: "1.5rem",
    },
    h6: {
      ...typographyHeader,
      fontWeight: typography.fontWeightMedium,
      fontSize: "1.1rem",
    },
    subtitle1: {
      ...typographyHeader,
      fontWeight: typography.fontWeightRegular,
      fontSize: "1.0rem",
    },
    body1: {
      ...typographyBody,
      fontSize: "1rem",
    },
    body2: {
      ...typographyBody,
      fontSize: typography.fontSize,
    },
    button: {
      ...typographyBody,
      fontSize: "0.82rem",
    },
    caption: {
      ...typographyBody,
      fontSize: "0.875rem",
      fontStyle: "italic",
    },
  },

  shape: {
    borderRadius: '2px',
  },

  overrides: {
    // React-Admin
    RaAppBar: {
      title: {
        textTransform: "capitalize",
      },
    },
    RaTopToolbar: {
      root: {
        alignItems: "center",
        "& > button, & > a": {
          fontSize: "14px",
          fontWeight: typography.fontWeightBold,
          paddingLeft: defaultMuiTheme.spacing(2),
          paddingRight: defaultMuiTheme.spacing(2),
          paddingTop: defaultMuiTheme.spacing(1),
          paddingBottom: defaultMuiTheme.spacing(1),
        }
      }
    },

    // Material-UI

    MuiAppBar: {
      root: {
        background: `linear-gradient(297deg, #6d6d6d, #231f30);`,
      },
    },
    MuiCard: {
      root: {
        border: "none",
      },
    },
    MuiIconButton: {
      root: {
        fontSize: "22px",
      },
    },
    MuiButton: {
      root: {
        color: palette.primary.main,
        paddingTop: defaultMuiTheme.spacing(1),
        paddingRight: defaultMuiTheme.spacing(4),
        paddingBottom: defaultMuiTheme.spacing(1),
        paddingLeft: defaultMuiTheme.spacing(4),
        textTransform: "none"
      },
      sizeSmall: {
        paddingTop: defaultMuiTheme.spacing(0),
        paddingRight: defaultMuiTheme.spacing(2),
        paddingBottom: defaultMuiTheme.spacing(0),
        paddingLeft: defaultMuiTheme.spacing(2),
      },
      sizeLarge: {
        paddingTop: defaultMuiTheme.spacing(2),
        paddingRight: defaultMuiTheme.spacing(6),
        paddingBottom: defaultMuiTheme.spacing(2),
        paddingLeft: defaultMuiTheme.spacing(6),
      },
      contained: {
        boxShadow: "none",
      },
      containedPrimary: {
        color: palette.common.white,
        backgroundColor: palette.primary.main,
      },
      containedSecondary: {
        color: palette.common.white,
        backgroundColor: palette.secondary.main,
      },
      outlined: {},
      outlinedPrimary: {
        color: palette.primary.main,
        borderColor: palette.primary.main,
      },
      outlinedSecondary: {
        color: palette.common.white,
        borderColor: palette.common.white,
      },
      text: {
        paddingTop: defaultMuiTheme.spacing(1),
        paddingRight: defaultMuiTheme.spacing(4),
        paddingBottom: defaultMuiTheme.spacing(1),
        paddingLeft: defaultMuiTheme.spacing(4),
      },
      textPrimary: {
        color: palette.primary.main,
      },
      textSecondary: {
        color: palette.secondary.main,
      },
      label: {},
    },

    // React-Admin

    RaSidebar: {
      drawerPaper: {
        backgroundColor: palette.sidebar.main,
        color: palette.sidebar.secondary,
        height: "100%",
        boxShadow:
          "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
      },
    },
    // RaMenuItemLink: {
    //   root: {
    //     //backgroundColor: "rgba(255, 255, 255, 0.1) !important",//palette.action.selected,
    //     transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    //     color: "rgba(255, 255, 255, 0.7)",

    //     '&:hover': {
    //       backgroundColor: "rgba(255, 255, 255, 0.05)",
    //       borderRightWidth: defaultMuiTheme.spacing(0.5),
    //       borderRightStyle: "solid",
    //     },
    //   },
    //   active: {
    //     borderLeftStyle: "none",
    //     borderRightColor: palette.sidebar.secondary,
    //     borderRightWidth: defaultMuiTheme.spacing(0.5),
    //     borderRightStyle: "solid",
    //     backgroundColor: "rgba(255, 255, 255, 0.1) !important",//palette.action.selected,
    //     transition: "border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s",
    //     color: palette.sidebar.secondary,
    //   },
    //   icon: {
    //     color: "inherit",
    //   },
    // },
    RaLayout: {
      content: {
        height: "auto",
        backgroundColor: palette.background.default,
        paddingTop: defaultMuiTheme.spacing(0),
        paddingRight: defaultMuiTheme.spacing(0),
        paddingBottom: defaultMuiTheme.spacing(0),
        paddingLeft: defaultMuiTheme.spacing(0),
        display: "flex",
        flexDirection: "column",
        [defaultMuiTheme.breakpoints.up("xs")]: {
          paddingTop: defaultMuiTheme.spacing(0),
          paddingRight: defaultMuiTheme.spacing(0),
          paddingBottom: defaultMuiTheme.spacing(0),
          paddingLeft: defaultMuiTheme.spacing(0),
        },
        "& > div, & > h2": {
          paddingTop: defaultMuiTheme.spacing(4),
          paddingRight: defaultMuiTheme.spacing(3),
          paddingBottom: defaultMuiTheme.spacing(3),
          paddingLeft: defaultMuiTheme.spacing(3),
          [defaultMuiTheme.breakpoints.up("xs")]: {
            paddingLeft: defaultMuiTheme.spacing(6),
          },
        },
      },
    },
    RaAppBar: {
      toolbar: {
        MuiIconButton: {
          root: {
            fontSize: "1.25rem",
          },
        },
      },
    },
    RaTabbedShowLayout: {
      content: {
        marginTop: defaultMuiTheme.spacing(4),
        backgroundColor: palette.common.white,
        boxShadow: defaultMuiTheme.shadows[3],
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    RaShow: {
      main: {
        marginTop: defaultMuiTheme.spacing(2),
      },
      noActions: {
        marginTop: defaultMuiTheme.spacing(2),
      },
    },
    RaFilter: {
      form: {
        alignItems: "center",
        "& > .filter-field": {
          alignItems: "center"
        },
      },

      button: {
        "& button": {
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: palette.grey[300],
          textTransform: "uppercase",
          fontWeight: typography.fontWeightBold,
          color: palette.primary.main,
          paddingLeft: defaultMuiTheme.spacing(2),
          paddingRight: defaultMuiTheme.spacing(2),
          paddingTop: defaultMuiTheme.spacing(1),
          paddingBottom: defaultMuiTheme.spacing(1),
        },
      },
    },
    RaListToolbar: {
      toolbar: {
        paddingBottom: defaultMuiTheme.spacing(0),
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: palette.grey[300],
        marginBottom: defaultMuiTheme.spacing(1),

      },
      actions: {
        marginRight: "0px",
        paddingTop: defaultMuiTheme.spacing(2),
        minHeight: 'auto',
        "& > button, & > a": {
          fontSize: "14px",
          fontWeight: typography.fontWeightBold,
          paddingLeft: defaultMuiTheme.spacing(2),
          paddingRight: defaultMuiTheme.spacing(2),
          paddingTop: defaultMuiTheme.spacing(1),
          paddingBottom: defaultMuiTheme.spacing(1),
        }
      },

    },
  },
};

export const theme = createMuiTheme(merge({}, defaultTheme, newTheme));