import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import InvoicesIcon from '@material-ui/icons/ReceiptOutlined';

import { AppConfig, AppRoute } from '../../types';
import Layout from './Layout';
// import SubjectEdit from './subject/SubjectEdit';
// import ExportSystem from './export-systems';

const temp = () => <div />;

const invoicesConfig: AppConfig = {
  // invoices: {
  // 	layout: {
  // 		config: {}
  // 	} //lazy(() => import('./invoicesEdit')),
  // },
  key: 'InvoicesApp',
  name: 'Fakturace',
  icon: InvoicesIcon,

  routes: [
    {
      group: 'home',
      key: 'invoices/list',
      name: 'Fakturace',
      path: '/invoices',
      icon: InvoicesIcon,
      customRouteProps: {
        component: Layout,
      },
    },
    {
      group: 'invoices',
      key: 'invoices/default-invoices',
      name: 'defaultinvoices',
      path: '/invoices/default-invoices',
      icon: InvoicesIcon,
      customRouteProps: {
        component: temp,
      },
    },
    // {
    //   group: 'invoices',
    //   key: 'invoices',
    //   name: 'invoices',
    //   path: '/invoices',
    //   invisible: true,
    //   customRouteProps: {
    //     render: (props: any) => {
    //       return <Redirect to="/invoices" />;
    //     },
    //   },
    // },
  ],
};

export default invoicesConfig;
