import React from 'react';
import { Admin, Resource, ResourceProps } from 'react-admin';
import { Route } from 'react-router';
import jsonServerProvider from 'ra-data-json-server';

import { createMuiTheme } from '@material-ui/core';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';

import Layout from './layouts/layout-vertical/Layout';

import { authProvider } from './authProvider';
import Dashboard from './pages/dashboard';
import { UserList } from './pages/users';
import { PostList, PostEdit, PostCreate } from './pages/posts';
import tags from './pages/tags';
import hotels from './pages/hotels';
import owners from './pages/owners';

import { ProfileEdit } from './pages/profiles/ProfileEdit';
import settings from './pages/settings';
// import defaultTheme from './themes/theme';
import { theme } from './theme';
import i18nProvider from './i18n/i18nProvider';
//import dataProvider from './dataProvider';

import { dataProvider } from './fakeDataProvider';
import { appReducer } from './store/reducer';
import appsConfigs from './pages/appsConfigs';
import { AppRoute } from './types';
import { generateRoutesFromConfigs } from './utils';
//import { config } from 'process';
import { AppContext } from './AppContext';
// import {
// 	//TailwindColorGroup, // This one is used in the component's logic to cast a color group
// 	TailwindConfig
// } from 'tailwindcss/tailwind-config';
// import resolveConfig from 'tailwindcss/resolveConfig';
// import tailwindConfigModule from './tailwind/tailwind.config.js';

//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

// //const tailwindConfig = resolveConfig(tailwindConfigModule);

// //const tailwindConfig = resolveConfig(tailwindConfigModule as unknown as TailwindConfig);
// //console.log(tailwindConfig);
// const theme = createMuiTheme({
// 	palette: {
// 		primary: {
// 			main: tailwindConfig?.theme?.colors?.primary
// 		}
// 	}
// });

//const theme = createMuiTheme(defaultTheme);

//const routes = appsConfigs.reduce((prev, current, index, [])=> [...prev, current.map] );

let allRoutes = generateRoutesFromConfigs(appsConfigs);
console.log(allRoutes);
const App = () => (
  // <MuiThemeProvider theme={theme}>
  <AppContext.Provider value={{ routes: allRoutes, appsConfigs: appsConfigs }}>
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      layout={Layout}
      theme={theme}
      customReducers={appReducer}
      customRoutes={[
        <Route key="my-profile" path="/my-profile" render={() => <ProfileEdit />} />,
        // <Route key="settings" path="/settings" render={() => <LayoutSet />} />,
        ...allRoutes
          .filter(p => !!p.customRouteProps && p.route)
          .map(({ customRouteProps, key, route, path, ...config }) => (
            <Route
              key={key}
              path={route}
              component={customRouteProps?.component}
              render={customRouteProps?.render}
              exact
            />
          )),
        // <Route key="settings/theme" path="/settings/theme" render={() => <ProfileEdit />} />,
      ]}
      disableTelemetry
    >
      <Resource name="users" list={UserList} icon={UserIcon} />
      <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate} icon={PostIcon} />
      <Resource name="albums" {...tags} />
      <Resource name="hotels" {...hotels} />
      <Resource name="owners" {...owners} />
      {allRoutes
        .filter(p => !!p.resourceRouteProps)
        .map(({ resourceRouteProps, name, key, path, ...config }, index) => (
          <Resource
            key={key}
            {...(resourceRouteProps as ResourceProps)}
            // options={{ label: name }}
            // list={({ permissions }) => <span>Permissions: {permissions}</span>}
            // match={{
            //   url: '',
            //   params: {},
            //   isExact: false,
            //   path: path,
            // }}
          />
        ))}
      {/* <Resource name="settings" {...settings} /> */}
    </Admin>
  </AppContext.Provider>
  // </MuiThemeProvider>
);

export default App;
