import React, { Component, ErrorInfo, HtmlHTMLAttributes, useRef, useEffect, useState, createElement } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { CoreLayoutProps } from 'react-admin';

import { Notification, Error, Sidebar } from 'react-admin';
// @ts-ignore
import classnames from 'classnames';
import { connect } from 'react-redux';
import { createMuiTheme, withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'lodash/flowRight';

import Header from './Header';
import Menu from './AppMenu';
import SettingsPanel from '../../containers/config/SettingsPanel';
import NotificationPanel from '../../containers/notification/NotificationPanel';

class LayoutWithoutTheme extends Component<LayoutWithoutThemeProps, LayoutState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined, errorInfo: undefined };
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorInfo,
    });
  }

  render() {
    const { title, classes, className, children, dashboard, ...props } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    return (
      <>
        <div className={classnames('layout', classes.root, className)}>
          <CssBaseline />
          <Header />
          {/* <Container maxWidth="xl"> */}
          <main className={classes.contentWithSidebar}>
            {
              // @ts-ignore
              createElement(Sidebar, {
                children: createElement(Menu, {
                  hasDashboard: true,
                }),
              })
            }
            <div id="main-content" className={classes.content}>
              {hasError ? (
                <Error
                  error={errorMessage as unknown as string} // FIXME
                  errorInfo={errorInfo}
                  title={title as string}
                />
              ) : (
                <React.Fragment>
                  {children}
                  <SettingsPanel />
                  <NotificationPanel />
                </React.Fragment>
              )}
            </div>
          </main>
          {/* </Container> */}
        </div>
        <Notification />
      </>
    );
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    title: PropTypes.node.isRequired,
    classes: PropTypes.object,
  };
}

export interface LayoutProps extends CoreLayoutProps, Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  //PropTypes.InferProps<typeof propTypes>,
  classes?: any;
}

interface LayoutWithoutThemeProps extends RouteComponentProps, Omit<LayoutProps, 'theme'> {
  open?: boolean;
}

//Layout.propTypes = propTypes;

export interface LayoutState {
  hasError: boolean;
  errorMessage?: string;
  errorInfo?: ErrorInfo;
}

const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(7),
      },
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      //padding: theme.spacing(3),
      //paddingTop: theme.spacing(1),
      paddingLeft: 0,
      [theme.breakpoints.up('xs')]: {
        //paddingLeft: 5,
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  });

const mapStateToProps = (state: any) => ({
  open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'RaLayout' })
)(LayoutWithoutTheme);

const Layout = ({ theme: themeOverride, ...props }: LayoutProps): JSX.Element => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(() => createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

// @ts-ignore
export default Layout;
