import { lazy } from 'react';
import { linkToRecord, ResourceMatch } from 'react-admin';
import { Redirect } from 'react-router-dom';
//import HotelIcon from '@material-ui/icons/Hotel';
import HotelIcon from '@material-ui/icons/HotelOutlined';
import ExportsIcon from '@material-ui/icons/SyncOutlined';
import LeftMenu from './LeftMenu';
import { AppConfig, AppRoute } from '../../types';

const HotelConfig: AppConfig = {
  key: 'HotelsApp',
  name: 'Hotely',
  icon: HotelIcon,
  appMenu: <LeftMenu />,
  routes: [
    {
      group: 'HotelsApp',
      key: 'Hotel/list',
      name: 'Výpis hotelů',
      path: '/hotels',
      icon: HotelIcon,
      invisible: false,
    },
    {
      group: 'HotelsApp',
      key: 'Hotel/export',
      name: 'Exportní systémy',
      path: '/hotels/exports',
      icon: ExportsIcon,
      invisible: false,
    },
  ],
};

export default HotelConfig;
