import { FC } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { PageRefProps } from '../PageSimple';
import SidebarMenuButton from './SidebarMenuButton';
import { makeStyles } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/core/styles';
// import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux';
// import { selectMainTheme } from 'app/store/fuse/Slice';
// import { setMailsSearchText } from './store/mailsSlice';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    position: 'static',
    right: 'auto',
    top: 'auto',
  },
}));

export interface HeaderProps {
  pageLayout: React.RefObject<PageRefProps>;
}

const Header: FC<HeaderProps> = props => {
  //   const dispatch = useDispatch();
  //   const searchText = useSelector(({ mailApp }) => mailApp.mails.searchText);
  //   const mainTheme = useSelector(selectMainTheme);
  //   const { t } = useTranslation('mailApp');
  const classes = useStyles();
  return (
    <div className="flex flex-1">
      <Paper className="flex items-center w-full h-10 sm:h-16 p-16 ltr:pl-4 lg:ltr:pl-16 rtl:pr-4 lg:rtl:pr-16 shadow">
        <Hidden lgUp>
          {/* <IconButton onClick={ev => props.pageLayout?.current?.toggleLeftSidebar()} aria-label="open left sidebar">
            <Icon>menu</Icon>
          </IconButton> */}
          <SidebarMenuButton ariaLabel="open left sidebar" className={classes.headerRoot} />
        </Hidden>

        {props.children}

        {/* 
        <Icon color="action">search</Icon>

        <Input
          //  placeholder={t('SEARCH_PLACEHOLDER')}
          className="px-16"
          disableUnderline
          fullWidth
          // value={searchText}
          inputProps={{
            'aria-label': 'Search',
          }}
          // onChange={ev => dispatch(setMailsSearchText(ev))}
        /> */}
      </Paper>
    </div>
  );
};

export default Header;
