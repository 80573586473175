import polyglotI18nProvider from 'ra-i18n-polyglot';
import czechMessages from './cs';



const messages = {
  en: () => import('./en.js').then(messages => messages.default),
};

export default polyglotI18nProvider(locale => {
  if (locale === 'en') {
    return messages[locale]();
  }

  // Always fallback on english
  return czechMessages;
}, 'cs');
