import { Reducer } from "redux";
import { ConfigAction, CHANGE_THEME, ConfigState, Theme } from "./types";
import { DRAWER_TOGGLED, DrawerToggledAction } from "./types";

export const initialConfigState: () => ConfigState = () => ({
  theme: Theme.Light,
  drawerToggled: false
});

type ConfigReducer = Reducer<ConfigState, ConfigAction>;
export const configReducer: ConfigReducer = (state = initialConfigState(), action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload
      };
      case DRAWER_TOGGLED:
      return {
        ...state,
        drawerToggled: action.payload
      };
    default:
      return state;
  }
};
