import * as React from 'react';
import SidebarMenu from '../../../components/sidebar/SidebarMenu';
import HotelIcon from '@material-ui/icons/HotelOutlined';

import { generateRoutesFromConfigs } from '../../../utils';
import { AppConfig, AppRoute } from '../../../types';
import HotelConfig from './HotelEditConfig';

export interface ILeftMenuProps {}

export default function LeftMenu(props: ILeftMenuProps) {
  let allRoutes = generateRoutesFromConfigs([HotelConfig]);

  return <SidebarMenu routes={allRoutes}></SidebarMenu>;
}
