import { useSelector } from 'react-redux';
import { useTheme, ThemeProvider } from '@material-ui/core/styles';
//import { selectContrastMainTheme } from 'app/store//settingsSlice';
import { PageSidebarVariantType } from './PageSimpleSidebar';
import clsx from 'clsx';

export interface PageSidebarContentProps {
  classes: any;
  header: any;
  innerScroll: boolean;
  content: any;
  variant: PageSidebarVariantType;
}

const PageSimpleSidebarContent: React.FC<PageSidebarContentProps> = props => {
  //const theme = useTheme();
  //const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));

  const { classes } = props;

  return (
    <div>
      {/* <Scrollbars enable={props.innerScroll}> */}
      {props.header && (
        //   <ThemeProvider theme={contrastTheme}>
        <div
          className={clsx(
            classes.sidebarHeader,
            props.variant
            //props.sidebarInner && classes.sidebarHeaderInnerSidebar
          )}
        >
          {props.header}
        </div>
        //   </ThemeProvider>
      )}
      {props.content && <div className={classes.sidebarContent}>{props.content}</div>}

      {/* </Scrollbars> */}
    </div>
  );
};

export default PageSimpleSidebarContent;
