import * as React from 'react';
import { useCallback, FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { refreshView, useTranslate } from 'ra-core';
import { AppState } from '../../store/types';
import { drawerToggle } from './store/actions';
import NotificationBadge from '../../components/badge/NotificationBadge';

const NotificationIconButton: FC<NotificationIconButtonProps> = ({
  label = 'ra.action.notifications',
  icon,
  onClick,
  className,
  ...rest
}) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const isdrawerToggled = useSelector<AppState, boolean>(appState => appState.notification.drawerToggled);
  const bangeValue = useSelector<AppState, number>(appState => appState.notification.bangeValue);

  const handleClick = useCallback(
    event => {
      event.preventDefault();

      dispatch(drawerToggle(!isdrawerToggled)); // TODO
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [dispatch, onClick, isdrawerToggled]
  );

  return (
    <Tooltip title={label && translate(label, { _: label })}>
      <IconButton
        // aria-label={label && translate(label, { _: label })}
        aria-label="show 17 new notifications"
        className={className}
        color="inherit"
        onClick={handleClick}
        {...rest}
      >
        <NotificationBadge badgeContent={bangeValue} color="error">
          {isdrawerToggled || bangeValue > 0 ? <NotificationsIcon /> : <NotificationsOutlinedIcon />}
        </NotificationBadge>
      </IconButton>
    </Tooltip>
  );
};

interface Props {
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
}

export type NotificationIconButtonProps = Props & IconButtonProps;

NotificationIconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};

export default NotificationIconButton;
