import React, { MouseEventHandler, FC } from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  maxLength,
  regex,
  SaveButton,
  Toolbar,
  useTranslate,
  CreateProps,
  ToolbarProps,
} from 'react-admin';
import Button from '@material-ui/core/Button';

// interface OwnerCreateProps extends CreateProps {
//   onCancel?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
// }

// const OwnerCreateToolbar = ({ onCancel, ...rest }: Partial<ToolbarProps> & OwnerCreateProps) => {
//   const translate = useTranslate();
//   return (
//     <Toolbar {...(rest as unknown)}>
//       <SaveButton />
//       <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
//     </Toolbar>
//   );
// }; toolbar={<OwnerCreateToolbar onCancel={onCancel} />}

const validateSlugValue = regex(/^[a-z][a-z0-9\-]+$/, 'Must be a valid Slug [a-z0-9] or -');

const OwnerCreate: FC<CreateProps> = (props: CreateProps) => (
  <Create title=" " {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="slug" validate={[required(), maxLength(255), validateSlugValue]} />
      <TextInput source="category" />
      <TextInput source="destination" />
    </SimpleForm>
  </Create>
);

export default OwnerCreate;
