import React, { useRef, FC } from 'react';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';

import PageSimple from '../../containers/PageSimple';
import Header from '../../containers/PageSimple/Partials/Header';
import SidebarContent from '../../containers/PageSimple/Partials/SidebarContent';
import SidebarHeader from '../../containers/PageSimple/Partials/SidebarHeader';

import { generateRoutesFromConfigs } from '../../utils';
import { AppConfig } from '../../types';
import InvoicesConfig from './InvoicesConfig';

interface Props {}

type SettingsEditProps = Props;

const SettingsLayout: FC<SettingsEditProps> = props => {
  const pageLayout = useRef(null);

  let allRoutes = generateRoutesFromConfigs([InvoicesConfig]);
  const header = () => <Header pageLayout={pageLayout}></Header>;

  return (
    <PageSimple
      // classes={{
      //   contentWrapper: 'p-0 sm:p-24 h-full',
      //   content: 'flex flex-col h-full',
      //   leftSidebar: 'w-256 border-0',
      //   header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
      //   wrapper: 'min-h-0',
      // }}
      header={<Header pageLayout={pageLayout}></Header>}
      content={props.children}
      leftSidebarHeader={
        <SidebarHeader title="resources.invoices.section" icon={<AccountBalanceWalletTwoToneIcon />} />
      }
      leftSidebarContent={<SidebarContent routes={allRoutes} />}
      ref={pageLayout}
      innerScroll
    />
  );
};

export default SettingsLayout;
