import * as React from 'react';
import { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate, FieldProps, PublicFieldProps, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const ChipArrayField: FC<PublicFieldProps> = ({ source }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext();
  debugger;
  if (!!!source) {
    return null;
  }

  let value = get(record, source as string) as string[];

  return record ? (
    <span className={classes.main}>
      {value &&
        value.map((chipValue, index) => {
          return <Chip size="small" key={index} className={classes.chip} label={translate(chipValue)} />;
        })}
    </span>
  ) : null;
};

ChipArrayField.defaultProps = {
  addLabel: true,
  source: 'groups',
};

export default ChipArrayField;
