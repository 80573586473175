import list from './OwnerList';
import edit from './OwnerEdit';
import create from './OwnerCreate';
import ShopOutlined from '@material-ui/icons/ShopOutlined';

export default {
  list,
  create,
  edit,
  icon: ShopOutlined,
};
