import _ from 'lodash';
import {AppRoute, AppConfig} from '../types'

export const setRoutes = (config: AppConfig, defaultAuth?:string):AppRoute[] =>{
		let routes:AppRoute[] = [];

		routes = config.routes.map(route => {
			let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null;
			auth = route.auth || route.auth === null ? route.auth : auth;
			const settings = _.merge({}, config.settings, route.settings);
            
			return {
				...route,
				settings,
				auth
			} as AppRoute;
		});

		return [...routes];
	}

    export const generateRoutesFromConfigs = (configs: AppConfig[], defaultAuth?:string):AppRoute[] => {
		
        let allRoutes:AppRoute[] = [];
		configs.forEach(config => {
			allRoutes = [...allRoutes, ...setRoutes(config, defaultAuth)];
		});
        
		return allRoutes;
	} 