import React, { useRef, FC } from 'react';
import { ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core';

import { Portal } from 'react-portal';
import HotelEditOutlined from '@material-ui/icons/HotelOutlined';
import SidebarContainer from '../sidebar/SidebarContainer';
import BreadScrumbs from '../navigation/BreadScrumbs';
import HotelConfig from '../../pages/hotels/HotelConfig';

interface Props {
  leftMenu: React.ReactNode;
}

type LayoutWithSidebarProps = Props;

const LayoutWithSidebar: FC<LayoutWithSidebarProps> = props => {
  const { leftMenu } = props;
  const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'));
  const open = useSelector<ReduxState>(app => app.admin.ui.sidebarOpen);

  return (
    <div>
      <Portal node={document && document.getElementById('app-menu')}>
        <SidebarContainer>{leftMenu}</SidebarContainer>
      </Portal>
      <>
        <BreadScrumbs routes={[HotelConfig.routes[0]]} />

        {props.children}
      </>
    </div>
  );
};

export default LayoutWithSidebar;
