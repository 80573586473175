import React, { MouseEventHandler, FC } from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleForm,
  required,
  maxLength,
  regex,
  SaveButton,
  Toolbar,
  useTranslate,
  EditProps,
  ToolbarProps,
  FormGroupContextProvider,
  useRecordContext,
  useGetOne,
  useEditContext,
  useFormGroup,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ExportSystem } from './types';
import Layout from '../Layout';

const ExportSystemEdit: FC<EditProps> = (props: EditProps) => {
  const translate = useTranslate();

  return (
    <Layout>
      <Container maxWidth="xl">
        <Edit title="Editace exportního systému" {...props}>
          <SimpleForm component={CustomLayout}>
            <TextInput source="name" fullWidth validate={required()} />

            <CustomDivider />
            <BooleanInput source="isActiveForSync" />
            <BooleanInput source="isActiveForSale" />

            <CustomDivider />

            <TextInput source="initializeFactoryType" fullWidth validate={required()} />

            <CustomDivider />
          </SimpleForm>
        </Edit>
      </Container>
    </Layout>
  );
};

const CustomLayout = (props: any) => (
  <CardContent>
    <Box display="flex">
      <Box paddingTop={1}>
        <Avatar>
          <SyncOutlinedIcon />
        </Avatar>
      </Box>
      <Box ml={2} flex="1" maxWidth={796}>
        {props.children}
      </Box>
    </Box>
  </CardContent>
);

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

export default ExportSystemEdit;
