import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  email,
  Button,
  SaveButton,
  TextInput,
  DateTimeInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  FormWithRedirectRenderProps,
  FormWithRedirectSave,
  Record,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  regex,
  useTranslate,
  EditBase,
  EditProps,
  useEditContext,
  RedirectionSideEffect,
  OnSuccess,
  OnFailure,
  useRefresh,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';

import { makeStyles } from '@material-ui/core/styles';

import { Hotel } from '../types';

const useStyles = makeStyles({
  root: {},
  divider: {
    width: '1px',
    height: '28px',
  },
  MuiInputAdornmentPositionEnd: {
    marginLeft: '8px',
  },
  adornedEnd: {
    paddingRight: '0',
  },
  rightButton: {
    padding: '7px',
    borderRadius: '0',
  },
  overrides: {
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0',
      },
    },
  },
  inline: {
    display: 'inline-block',
    marginLeft: '1em',
    '&.first-child': {
      marginLeft: 0,
    },
  },
});

interface Props extends EditProps {
  open: boolean;
  onChange: () => void;
  onClose: () => void;
  className: string;
  variant: 'checkIn' | 'checkOut';
}

function HotelEditContent({ onChange, className, open, onClose, variant }: Props) {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { save, record } = useEditContext();

  const handleSubmit = async (
    data: Partial<Hotel>,
    redirectTo: RedirectionSideEffect,
    options?: { onSuccess?: OnSuccess; onFailure?: OnFailure }
  ) => {
    if (save) {
      const tempData: Partial<Hotel> = {};

      tempData[`${variant}`] = {
        from: data[`${variant}`].from,
        to: data[`${variant}`].to,
      };

      save(tempData, redirectTo, {
        onSuccess: (response: any) => {
          notify(
            'ra.notification.updated',
            'info',
            {
              smart_count: 1,
            },
            true
          );
          onChange();
        },
        onFailure: ({ error }: any) => {
          notify(error.message, 'error');
          refresh();
        },
      });
    }
  };

  const validateTime = regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Must be a valid time');

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose} aria-label="Editace hotelu">
        <DialogTitle>{translate(`resources.hotelsedit.fields.${variant}`)}</DialogTitle>

        <FormWithRedirect
          // resource="hotels"
          save={handleSubmit}
          redirect="show"
          record={record as any}
          render={({ handleSubmitWithRedirect, pristine, saving, valid }: FormWithRedirectRenderProps) => (
            <form>
              <DialogContent>
                <TextInput source={`${variant}.from`} label="Od" fullWidth validate={validateTime} />
                <TextInput source={`${variant}.to`} label="Do" fullWidth validate={validateTime} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose}>
                  {/* // disabled={loading} */}
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  disabled={pristine || !valid} //|| loading
                  saving={saving}
                />
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
}

export const HotelCheckInOutEditDialog = (props: Props) => (
  <EditBase
    {...props}
    transform={(data: Hotel) => ({
      ...data,
      last_seen: new Date(),
    })}
  >
    <HotelEditContent {...props} />
  </EditBase>
);

export default HotelCheckInOutEditDialog;
