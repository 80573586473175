import React, { FC } from 'react';
import { useShowContext, TextField, useTranslate, ShowProps, useRecordContext, Translate } from 'react-admin';

import Box from '@material-ui/core/Box';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SheduleIcon from '@material-ui/icons/AlarmOutlined';
import SheduleOffIcon from '@material-ui/icons/AlarmOffOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

import get from 'lodash/get';

import CheckInOutTextField from './CheckInOutTextField';
import HotelCheckInOutEditDialog from './HotelCheckInOutEditDialog';
import { Hotel, CheckInTo } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    avatar: {
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  })
);

// const CheckInOut = ({ variant, record }: { variant: 'checkIn' | 'checkOut', record: Hotel }) => {

//  let from = get(record, `${variant}.from`);
//  let to = get(record, `${variant}.to`);

//   if (!!!from) {
//     from = 'neomezeno';
//   }
//   if (!!!to) {
//     to = 'neomezeno';
//   }

//   return (
//     <Box flexDirection="row">
//       <TextField source={`${variant}.from`} />
//       <Typography component="span" variant="body1">
//         {' - '}
//       </Typography>
//       <TextField source={`${variant}.to`} />
//     </Box>
//   );
// };

interface HotelCheckInOutProps extends ShowProps {
  variant: 'checkIn' | 'checkOut';
}

const HotelCheckInOut: FC<HotelCheckInOutProps> = props => {
  const { loading, ...context } = useShowContext<Hotel>(props);
  const { record } = context;
  const { variant } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label={variant} className={classes.avatar}>
              {variant === 'checkIn' ? <SheduleIcon /> : <SheduleOffIcon />}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={() => setShowDialog(true)}>
              <EditIcon />
            </IconButton>
          }
          title={<Typography variant="subtitle1">{translate(`resources.hotelsedit.fields.${variant}`)}</Typography>}
          subheader={<CheckInOutTextField variant={variant} />}
        />

        {/* <CardContent>
          <TextField source="checkIn.from" />
        </CardContent>
        <CardActions disableSpacing>
          <Button size="small" color="primary">
            {translate('ra.action.edit')}
          </Button>
        </CardActions> */}
      </Card>

      <HotelCheckInOutEditDialog
        {...props}
        onChange={() => setShowDialog(false)}
        onClose={() => setShowDialog(false)}
        open={showDialog}
        className=""
      />
    </>
  );
};

export default HotelCheckInOut;
