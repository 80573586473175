import React from 'react';
import Badge from '@material-ui/core/Badge';
import { BadgeProps } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Theme, makeStyles, withStyles, createStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      //   backgroundColor: '#44b700',
      //   color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
)(Badge);

interface Props {
  disableRipple?: boolean;
}

export type NotificationBangeProps = Props & BadgeProps;

const NotificationBange = ({ disableRipple = false, ...rest }: NotificationBangeProps) => {
  return disableRipple ? (
    <Badge {...rest}>{rest.children}</Badge>
  ) : (
    <StyledBadge {...rest}>{rest.children}</StyledBadge>
  );
};

export default NotificationBange;
