import React, { Fragment, useCallback, FC } from 'react';
import Box from '@material-ui/core/Box';
import { ListProps, useTranslate } from 'react-admin';
import BulkChangeDataButton, { MenuItemProps } from '../../../components/buttons/BulkChangeDataButton';

const changeActivityMenu: MenuItemProps[] = [
  {
    id: '1',
    label: 'resources.hotels.action.bulk_change_hotel_activity.activate',
    onClick: () => console.log('klik aktivace'),
  },
  {
    id: '2',
    label: 'resources.hotels.action.bulk_change_hotel_activity.deactivate',
    onClick: () => console.log('klik deaktivace'),
  },
];

const handleChange = (data: MenuItemProps) => {
  console.log(data);
};

const BulkChangeActiviteButton = (props: any) => {
  var translate = useTranslate();
  return (
    <BulkChangeDataButton
      id="btn-1"
      label="resources.hotels.action.bulk_change_hotel_activity.title"
      {...props}
      menu={changeActivityMenu}
      onChange={data => handleChange(data)}
    />
  );
};

export default BulkChangeActiviteButton;
