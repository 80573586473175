import hotels from './pages/hotels/hotels-data'
import {Hotel} from './pages/hotels/types';

import owners from './pages/owners/owner-data'
import {Owner} from './pages/owners/types';

import exportSystems from './pages/settings/export-systems/export-system-data'
import {ExportSystem} from './pages/settings/export-systems/types';


export interface Db {
    hotels: Hotel[];
    owners: Owner[];
    exportSystems:ExportSystem[];
}


export {
    hotels,
    owners,
    exportSystems
}


export default (): Db => {
    const db = {} as Db;
    db.hotels = hotels;
    db.owners = owners;
    db.exportSystems = exportSystems;
//    finalize(db);

    return db;
};
