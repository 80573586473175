import React, { createContext, useState, useCallback, useMemo, useContext } from 'react';
import {
  TextInput,
  ImageInput,
  ImageField,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  useGetIdentity,
  EditProps,
} from 'react-admin';

import Container from '@material-ui/core/Container';

import { useProfile } from './ProfileContext';

interface Props extends EditProps {
  staticContext?: any;
}

interface Profile {
  fullName: string;
  avatar: string;
}

export const ProfileEdit = ({ staticContext, ...props }: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState<boolean>();
  const { refreshProfile } = useProfile();

  const { loaded, identity } = useGetIdentity();

  const handleSave = useCallback(
    values => {
      setSaving(true);
      dataProvider.updateUserProfile(
        { data: values },
        {
          onSuccess: ({ data }: any) => {
            setSaving(false);
            notify('Your profile has been updated', 'info', {
              _: 'Your profile has been updated',
            });
            refreshProfile();
          },
          onFailure: () => {
            setSaving(false);
            notify('A technical error occured while updating your profile. Please try later.', 'warning', {
              _: 'A technical error occured while updating your profile. Please try later.',
            });
          },
        }
      );
    },
    [dataProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (!loaded) {
    return null;
  }

  return (
    <Container>
      <SaveContextProvider value={saveContext}>
        <SimpleForm save={handleSave} record={identity ? identity : {}}>
          <TextInput source="fullName" validate={required()} />
          <ImageInput source="avatar" validate={required()}>
            <ImageField />
          </ImageInput>
        </SimpleForm>
      </SaveContextProvider>
    </Container>
  );
};
