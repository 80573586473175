import * as React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { useRouteMatch } from 'react-router-dom';
// @ts-ignore
import { useMediaQuery, Theme } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@material-ui/icons/ViewList';
import SidebarMenuOpen from '@material-ui/icons/MenuOpen';
// @ts-ignore
import classnames from 'classnames';
import { useGetResourceLabel, getResources, ReduxState } from 'ra-core';

import { useTranslate } from 'react-admin';
import { AppRoute } from '../../types';
import SidebarMenuItemLink from './MenuItemLink';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
export const SidebarMenu_WIDTH = '17.0rem';
export const CLOSED_SidebarMenu_WIDTH = 0;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0',
      padding: '0',
    },
    title: {
      marginLeft: theme.spacing(2),
      alignSelf: 'center',
    },
    open: {
      width: lodashGet(theme, 'SidebarMenu.width', SidebarMenu_WIDTH),
      display: 'flex',
    },
    closed: {
      width: '0',
      display: 'none',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      '& .list-item-icon': {
        minWidth: 'auto',
        display: 'block',
      },
    },
    SidebarMenubutton: {
      position: 'absolute',
      right: '0px',
      top: '10px',
      fontSize: '28px',
      '& svg': {
        fontSize: '26px',
      },
    },
  }),
  { name: 'HPSidebarMenu' }
);

const SidebarMenu: FC<SidebarMenuProps> = props => {
  const { classes: classesOverride, className, dense, onSidebarMenuClick, routes, ...rest } = props;
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.only('md'));
  const isSmallUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const translate = useTranslate();

  return (
    <div>
      <List
        className={classnames(
          classes.main,
          {
            [classes.open]: open,
            [classes.closed]: !open,
          },
          className
        )}
        {...rest}
      >
        {routes.map(resource => (
          <SidebarMenuItemLink
            key={resource.key}
            to={{
              pathname: `${resource.path}`,
              state: { _scrollToTop: true },
            }}
            primaryText={translate(resource.name, { _: resource.name })}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onSidebarMenuClick}
            dense={dense}
          />
        ))}
      </List>
      {/* {isXSmall && logout} */}
    </div>
  );
};

export interface SidebarMenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  onSidebarMenuClick?: () => void;
  routes: AppRoute[];
}

SidebarMenu.propTypes = {
  classes: PropTypes.object,
  //routes: PropTypes.array<AppRoute>,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  onSidebarMenuClick: PropTypes.func,
};

SidebarMenu.defaultProps = {
  onSidebarMenuClick: () => null,
};

export default SidebarMenu;
