import React, { createContext, useState, useCallback, useMemo, useContext, ReactElement, ProviderProps } from 'react';

interface ProfileProviderProps {
  profileVersion: number;
  refreshProfile: () => void;
}

const defaultProvider: ProfileProviderProps = {
  profileVersion: 0,
  refreshProfile: () => {},
};

const ProfileContext = createContext<ProfileProviderProps>(defaultProvider);

export const ProfileProvider = ({ children }: { children: ReactElement }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => setProfileVersion(currentVersion => currentVersion + 1),
    }),
    [profileVersion]
  );

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);
