import React, { Fragment, useCallback, FC } from 'react';
import Box from '@material-ui/core/Box';
import StarRateIcon from '@material-ui/icons/StarRate';
import { ListProps, useTranslate } from 'react-admin';
import BulkChangeDataButton, { MenuItemProps } from '../../../components/buttons/BulkChangeDataButton';

const changeRateMenu: MenuItemProps[] = [
  { id: '1', label: <StarRateIcon htmlColor="orange" />, onClick: () => console.log('klik aktivace') },
  {
    id: '2',
    label: (
      <Box>
        <StarRateIcon htmlColor="orange" />
        <StarRateIcon htmlColor="orange" />
      </Box>
    ),
    onClick: () => console.log('klik deaktivace'),
  },
];

const BulkChangeRatingButton = (props: any) => {
  var translate = useTranslate();
  return (
    <BulkChangeDataButton
      id="btn-1"
      title="resources.hotels.action.bulk_change_hotel_rating.title"
      {...props}
      menu={changeRateMenu}
      variant="contained"
      onChange={data => console.log(data)}
      icon={<StarRateIcon htmlColor="orange" />}
    />
  );
};

//  <BulkChangeDataButton
//    // label="Změnit aktivitu hotelu2"
//    {...props}
//    id="btn-2"
//    title="Změnit důležitost hotelu"
//    menu={changeRateMenu}
//    onChange={data => console.log(data)}
//    variant="contained"
//    icon={<StarRateIcon htmlColor="orange" />}
//  />;

export default BulkChangeRatingButton;
