import React, { MouseEventHandler, FC } from 'react';
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  SaveButton,
  Toolbar,
  useTranslate,
  CreateProps,
  ToolbarProps,
} from 'react-admin';
import Button from '@material-ui/core/Button';

// interface TagCreateProps extends CreateProps {
//   onCancel?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
// }

type TagCreateProps = {
  onCancel: () => void;
};

const TagCreateToolbar = ({ onCancel, ...rest }: Partial<ToolbarProps> & TagCreateProps) => {
  const translate = useTranslate();
  return (
    <Toolbar {...(rest as unknown)}>
      <SaveButton />
      <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
    </Toolbar>
  );
};

const TagCreate: FC<CreateProps & TagCreateProps> = ({ onCancel, ...props }: CreateProps & TagCreateProps) => (
  <Create title=" " {...props}>
    <SimpleForm toolbar={<TagCreateToolbar onCancel={onCancel} />}>
      <TextInput source="title" validate={required()} />
    </SimpleForm>
  </Create>
);

export default TagCreate;
