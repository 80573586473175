import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
interface Props {
  className: string;
  alt: string;
}

const Logo = (props: Props) => {
  return (
    <SvgIcon
      width="100%"
      height="100%"
      viewBox="0 0 621 69"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeMiterlimit: 1.5,
      }}
      {...props}
    >
      <g id="Vertical-dark-logo">
        <g>
          <path
            d="M49.953,8.998l-0,59.167l-11.417,-0l-0,-25.25l-26.833,-0l-0,25.25l-11.5,-0l-0,-59.167l11.5,0l-0,23.917l26.833,-0l-0,-23.917l11.417,0Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M95.869,68.581c-4.333,0 -8.264,-0.847 -11.791,-2.541c-3.528,-1.695 -6.556,-3.945 -9.084,-6.75c-2.528,-2.806 -4.486,-6.014 -5.875,-9.625c-1.389,-3.611 -2.083,-7.306 -2.083,-11.084c-0,-3.944 0.736,-7.722 2.208,-11.333c1.472,-3.611 3.5,-6.806 6.084,-9.583c2.583,-2.778 5.652,-4.986 9.208,-6.625c3.555,-1.639 7.417,-2.459 11.583,-2.459c4.278,0 8.181,0.875 11.709,2.625c3.527,1.75 6.541,4.042 9.041,6.875c2.5,2.834 4.445,6.056 5.834,9.667c1.388,3.611 2.083,7.278 2.083,11c-0,3.944 -0.722,7.708 -2.167,11.292c-1.444,3.583 -3.444,6.75 -6,9.5c-2.555,2.75 -5.611,4.944 -9.166,6.583c-3.556,1.639 -7.417,2.458 -11.584,2.458Zm-17.166,-30c-0,2.556 0.388,5.028 1.166,7.417c0.778,2.389 1.903,4.5 3.375,6.333c1.472,1.834 3.278,3.306 5.417,4.417c2.139,1.111 4.569,1.667 7.292,1.667c2.777,-0 5.236,-0.57 7.375,-1.709c2.138,-1.139 3.944,-2.652 5.416,-4.541c1.472,-1.889 2.584,-4.014 3.334,-6.375c0.75,-2.361 1.125,-4.764 1.125,-7.209c-0,-2.555 -0.403,-5.027 -1.209,-7.416c-0.805,-2.389 -1.944,-4.5 -3.416,-6.334c-1.473,-1.833 -3.278,-3.291 -5.417,-4.375c-2.139,-1.083 -4.542,-1.625 -7.208,-1.625c-2.778,0 -5.25,0.57 -7.417,1.709c-2.167,1.139 -3.972,2.639 -5.417,4.5c-1.444,1.861 -2.541,3.972 -3.291,6.333c-0.75,2.361 -1.125,4.764 -1.125,7.208Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M183.453,19.081l-18.917,0l-0,49.084l-11.417,-0l0,-49.084l-18.916,0l-0,-10.083l49.25,0l-0,10.083Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M240.203,58.081l-0,10.084l-41.084,-0l0,-59.167l40.334,0l-0,10.083l-28.834,0l0,14.25l24.917,0l-0,9.334l-24.917,-0l0,15.416l29.584,0Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M257.536,68.165l-0,-59.167l11.5,0l-0,49.083l30.167,0l-0,10.084l-41.667,-0Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M340.619,68.165l0,-59.167l25.084,0c2.722,0 5.236,0.569 7.541,1.708c2.306,1.139 4.292,2.639 5.959,4.5c1.666,1.861 2.972,3.973 3.916,6.334c0.945,2.361 1.417,4.736 1.417,7.125c-0,2.5 -0.445,4.93 -1.333,7.291c-0.889,2.361 -2.139,4.459 -3.75,6.292c-1.612,1.833 -3.556,3.306 -5.834,4.417c-2.278,1.111 -4.778,1.666 -7.5,1.666l-14,0l0,19.834l-11.5,-0Zm11.5,-29.917l13.334,0c2.111,0 3.875,-0.861 5.291,-2.583c1.417,-1.723 2.125,-4.056 2.125,-7c0,-1.5 -0.222,-2.848 -0.666,-4.042c-0.445,-1.194 -1.028,-2.208 -1.75,-3.042c-0.723,-0.833 -1.57,-1.458 -2.542,-1.875c-0.972,-0.416 -1.958,-0.625 -2.958,-0.625l-12.834,0l0,19.167Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M426.119,68.581c-4.333,0 -8.264,-0.847 -11.791,-2.541c-3.528,-1.695 -6.556,-3.945 -9.084,-6.75c-2.528,-2.806 -4.486,-6.014 -5.875,-9.625c-1.389,-3.611 -2.083,-7.306 -2.083,-11.084c-0,-3.944 0.736,-7.722 2.208,-11.333c1.472,-3.611 3.5,-6.806 6.084,-9.583c2.583,-2.778 5.652,-4.986 9.208,-6.625c3.555,-1.639 7.417,-2.459 11.583,-2.459c4.278,0 8.181,0.875 11.709,2.625c3.527,1.75 6.541,4.042 9.041,6.875c2.5,2.834 4.445,6.056 5.834,9.667c1.388,3.611 2.083,7.278 2.083,11c-0,3.944 -0.722,7.708 -2.167,11.292c-1.444,3.583 -3.444,6.75 -6,9.5c-2.555,2.75 -5.611,4.944 -9.166,6.583c-3.556,1.639 -7.417,2.458 -11.584,2.458Zm-17.166,-30c-0,2.556 0.388,5.028 1.166,7.417c0.778,2.389 1.903,4.5 3.375,6.333c1.472,1.834 3.278,3.306 5.417,4.417c2.139,1.111 4.569,1.667 7.292,1.667c2.777,-0 5.236,-0.57 7.375,-1.709c2.138,-1.139 3.944,-2.652 5.416,-4.541c1.472,-1.889 2.584,-4.014 3.334,-6.375c0.75,-2.361 1.125,-4.764 1.125,-7.209c-0,-2.555 -0.403,-5.027 -1.209,-7.416c-0.805,-2.389 -1.944,-4.5 -3.416,-6.334c-1.473,-1.833 -3.278,-3.291 -5.417,-4.375c-2.139,-1.083 -4.542,-1.625 -7.208,-1.625c-2.778,0 -5.25,0.57 -7.417,1.709c-2.167,1.139 -3.972,2.639 -5.417,4.5c-1.444,1.861 -2.541,3.972 -3.291,6.333c-0.75,2.361 -1.125,4.764 -1.125,7.208Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M515.869,30.248l0,37.917l-11.5,-0l0,-59.167l8.917,0l30.833,38.833l0,-38.75l11.5,0l0,59.084l-9.333,-0l-30.417,-37.917Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <path
            d="M620.536,19.081l-18.917,0l0,49.084l-11.416,-0l-0,-49.084l-18.917,0l-0,-10.083l49.25,0l-0,10.083Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
          <ellipse
            cx="477.953"
            cy="15.371"
            rx="13.265"
            ry="13.542"
            style={{ fill: '#e2902e', stroke: '#fff', strokeWidth: '3.09px' }}
          />
          <path
            d="M472.203,38.581c1.743,0.62 3.692,0.966 5.75,0.966c2.057,0 4.006,-0.346 5.75,-0.966l-0,29.584l-11.5,-0l-0,-29.584Z"
            style={{ fill: '#fff', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Logo;
