import React, { MouseEventHandler, FC } from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  SimpleForm,
  required,
  maxLength,
  regex,
  SaveButton,
  Toolbar,
  useTranslate,
  CreateProps,
  ToolbarProps,
} from 'react-admin';

import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import Layout from '../Layout';

const ExportSystemCreate: FC<CreateProps> = (props: CreateProps) => (
  <Layout>
    <Container maxWidth="xl">
      <Create title=" " {...props}>
        <SimpleForm component={CustomLayout}>
          <TextInput source="name" fullWidth validate={required()} />
          <CustomDivider />
          <BooleanInput source="isActiveForSync" />
          <BooleanInput source="isActiveForSale" />

          <CustomDivider />
          <TextInput source="initializeFactoryType" fullWidth validate={required()} />
        </SimpleForm>
      </Create>
    </Container>
  </Layout>
);

const CustomLayout = (props: any) => (
  <CardContent>
    <Box display="flex">
      <Box paddingTop={1}>
        <Avatar>
          <SyncOutlinedIcon />
        </Avatar>
      </Box>
      <Box ml={2} flex="1" maxWidth={796}>
        {props.children}
      </Box>
    </Box>
  </CardContent>
);

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);

export default ExportSystemCreate;
