import React, { Fragment, useCallback, FC } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { push } from 'react-router-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  CardActions,
  CreateButton,
  EditButton,
  ListProps,
  TopToolbar,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  BulkExportButton,
  BulkActionsToolbarProps,
  Identifier,
  BooleanInput,
} from 'react-admin';

import ExportSystemCreate from './ExportSystemCreate';
import ExportSystemEdit from './ExportSystemEdit';
import ExportSystemListAside from './ExportSystemListAside';
import rowStyle from './rowStyle';
import Layout from '../Layout';
// import BulkChangeActiviteButton from './components/BulkChangeActiviteButton';
// import BulkChangeRatingButton from './components/BulkChangeRatingButton';

const useStyles = makeStyles({
  drawerContent: {
    width: 300,
  },
});

const ExportSystemFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="ra.action.search" source="q" alwaysOn />
      {/* <ReferenceInput label="User" source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput> */}

      {/* <SelectInput label="Slug" source="slug" optionText="slug" /> */}
    </Filter>
  );
};

// const ExportSystemListActions = ({ basePath, className }: { basePath?: string; className?: string }) => (
//   <TopToolbar className={className}>
//     <CreateButton basePath={basePath} />
//   </TopToolbar> actions={<ExportSystemListActions />} filters={<ExportSystemFilter />}
// );

const BulkActionButtons = (props: any) => (
  <Fragment>
    {/* <BulkChangeActiviteButton id="btn-1" {...props} />
    <BulkChangeRatingButton id="btn-2" {...props} /> */}

    <BulkExportButton label="Export" {...props} />
    {/* default bulk delete action */}
    {/* <BulkDeleteButton {...props} /> */}
  </Fragment>
);

export interface ExportSystemListProps extends ListProps {
  selectedRow?: Identifier;
}

const ExportSystemList: FC<ExportSystemListProps> = props => {
  const { selectedRow } = props;
  return (
    <Layout>
      <Container maxWidth="xl">
        <List
          {...props}
          sort={{ field: 'id', order: 'ASC' }}
          // aside={<ExportSystemListAside />}
          filters={<ExportSystemFilter />}
          bulkActionButtons={<BulkActionButtons />}
        >
          <Datagrid
            rowClick="edit"
            // @ts-ignore
            rowStyle={rowStyle(selectedRow)}
          >
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="isActiveForSync" />
            <BooleanField source="isActiveForSale" />
            <TextField source="initializeFactoryType" />
            <EditButton />
          </Datagrid>
        </List>
      </Container>
    </Layout>
  );
};

export default ExportSystemList;
