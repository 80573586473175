import React, { Fragment, ReactEventHandler, FC, ReactElement, SyntheticEvent, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDeactive from '@material-ui/icons/Cancel';
import ActionActive from '@material-ui/icons/CheckCircle';

// @ts-ignore
import classnames from 'classnames';
// @ts-ignore
import inflection from 'inflection';
import {
  useTranslate,
  MutationMode,
  Record,
  RedirectionSideEffect,
  useUpdate,
  OnSuccess,
  OnFailure,
  useResourceContext,
  Confirm,
  Button,
  ButtonProps,
  useRecordContext,
} from 'react-admin';

import { Hotel } from '../types';

const ChangeActivityWithConfirmButton: FC<ChangeActivityWithConfirmButtonProps> = props => {
  const {
    basePath,
    classes: classesOverride,
    className,
    mutationMode,
    onClick,
    redirect = 'list',
    onSuccess,
    onFailure,
    ...rest
  } = props;

  let {
    confirmTitle = 'resources.hotelsedit.action.deactive.confirm.title',
    confirmContent = 'resources.hotelsedit.action.deactive.confirm.content',
    icon = defaultIcon,
    label = 'resources.hotelsedit.action.deactive.label',
  } = props;

  const [isOpen, setOpen] = useState(false);
  const translate = useTranslate();
  const classes = useStyles(props);
  const resource = useResourceContext(props);
  const record = useRecordContext();
  //   const { open, loading, handleDialogOpen, handleDialogClose, handleDelete } = useDeleteWithConfirmController({
  //     record,
  //     redirect,
  //     basePath,
  //     mutationMode,
  //     onClick,
  //     onSuccess,
  //     onFailure,
  //     resource,
  //   });

  if (!record?.isActive) {
    confirmTitle = 'resources.hotelsedit.action.active.confirm.title';
    confirmContent = 'resources.hotelsedit.action.active.confirm.content';
    icon = activeDefaultIcon;
    label = 'resources.hotelsedit.action.active.label';
  }

  const [update, { loading, error }] = useUpdate<Hotel>('hotels', record?.id, { isActive: !record?.isActive });

  const handleDialogOpen = (e: any) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleChange = (e: any) => {
    update();
    setOpen(false);
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames(
          'ra-delete-button',
          record?.isActive ? classes.deactiveButton : classes.activeButton,
          className
        )}
        key="button"
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={isOpen}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true
            ),
          }),
          id: record?.id,
        }}
        onConfirm={handleChange}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const defaultIcon = <ActionDeactive />;
const activeDefaultIcon = <ActionActive />;

const useStyles = makeStyles(
  theme => ({
    deactiveButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    activeButton: {
      color: '#3ff436',
      '&:hover': {
        backgroundColor: fade('#3ff436', 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaChangeActivityWithConfirmButton' }
);

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  confirmTitle?: string;
  confirmContent?: React.ReactNode;
  icon?: ReactElement;
  label?: string;
  mutationMode?: MutationMode;
  onClick?: ReactEventHandler<any>;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  // May be injected by Toolbar - sanitized in Button
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
  onSuccess?: OnSuccess;
  onFailure?: OnFailure;
}

export type ChangeActivityWithConfirmButtonProps = Props & ButtonProps;

ChangeActivityWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default ChangeActivityWithConfirmButton;
